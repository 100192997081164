<template>
    <div>

        <v-text-field
                v-model="groupNo"
                placeholder="请输入分组编号"
                dense
                outlined
        ></v-text-field>

        <v-btn @click="click">查看监控</v-btn>

    </div>
</template>

<script>
    import TrtcMonitor from '@/infrastructures/trtc/monitor';
    import {Vue, Component, Inject} from 'vue-property-decorator';

    export default
    @Component
    class Demo extends Vue{
        name = window.$views.Demo;

        @Inject()
        notifier;

        @Inject()
        repository;

        @Inject()
        navigation;


        groupNo = "";
        monitor={};

        mounted(){
            this.monitor = TrtcMonitor.CreateMonitor(
                "01",
                "2"
            );
            console.log(this.monitor)
        }


        async click() {
            window.location.href="/#/ExaminationRoomMonitor/?roomId="+this.groupNo;
        }
    }
</script>
