<template>
    <div class="organization">
        <v-row>
            <v-col>
                <v-btn color="primary" class="bm-2" @click="addOrg">
                    新建组考机构
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    placeholder="请输入机构名称查询"
                    dense
                    solo
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
                <v-col>
                    <v-data-table
                        :search="search"
                        :headers="headers"
                        :items="organizationList"
                        no-data-text="暂无数据"
                    >
                        <template v-slot:item.options="{item}">
                            <v-btn
                                :to="$to($views.Organization.Detail, {
                                    organization: item.id,
                                })"
                                small
                                outlined
                                color="primary"
                                class="mx-2"
                                dark
                            >
                                账号管理
                            </v-btn>
                            <v-btn
                                @click="UpdOrganization(item)"
                                small
                                outlined
                                color="primary"
                                class="mx-2"
                                dark
                            >
                                编辑
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            <!-- <el-table :data="tableData" style="width: 100%">
                <el-table-column
                    prop="name"
                    label="组考机构名称"
                ></el-table-column>
                <el-table-column label="操作" width="500">
                    <template slot-scope="scope">
                        <v-btn
                            @click="handleClick(scope.row)"
                            :to="$to($views.Organization.Detail)"
                            color="primary"
                            style="margin-right: 20px"
                            small
                        >
                            账户管理
                        </v-btn>
                        <v-btn
                            color="primary"
                            :to="$to($views.Organization.BaseConfig)"
                            small
                        >
                            基础信息配置
                        </v-btn>
                    </template>
                </el-table-column>
            </el-table> -->
        </v-row>
        <!-- <v-row class="wrapper-page">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="page.cur"
                :page-sizes="[10, 20, 30]"
                :page-size.sync="page.size"
                layout="sizes,total, prev, pager, next, jumper"
                :total="page.totalCount"
            ></el-pagination>
        </v-row> -->

        <v-dialog v-model="showAddDialog" max-width="350px">
            <v-card class="mx-auto">
                <v-card-title
                    class="headline blue darken-1 white--text f-18"
                    dark
                    primary-title
                >
                    添加组考机构
                </v-card-title>
                 <v-card-text class="mt-6">
                    <div class="d-flex">
                        <label class="label">机构编码</label>
                        <v-text-field
                            v-model="saveModel.orgCode"
                            solo
                            type="number"
                            :disabled="editFlg"
                            dense
                            required
                            placeholder="机构编码"
                        ></v-text-field>
                    </div>
                </v-card-text>
                <v-card-text class="mt-6">
                    <div class="d-flex">
                        <label class="label">机构名称</label>
                        <v-text-field
                            v-model="saveModel.orgName"
                            solo
                            dense
                            required
                            placeholder="机构名称"
                        ></v-text-field>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="saveOrganization">保存</v-btn>
                    <v-btn
                        color="primary"
                        outlined
                        @click="showAddDialog = false"
                    >
                        关闭
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: window.$views.Organization.List.Index,
    inject: ['repository','notifier'],
    data() {
        return {
            saveModel: {
                orgCode: '',
                orgName: ''
            },
            addOrgCode:'',
            editFlg:false,
            showAddDialog: false,
            search: '',
            OrganizationName: '',
            page: {
                cur: 1,
                size: 10,
                count: 0,
                totalCount: 0,
            },
            organizationList: [],
            headers: [
                { text: '组考机构编码', value: 'orgCode' },
                { text: '组考机构名称', value: 'orgName' },
                { text: '操作', value: 'options' },
            ],
            tableData: [
                {
                    name: '王小虎1',
                },
                {
                    name: '王小虎2',
                },
                {
                    name: '王小虎3',
                },
                {
                    name: '王小虎4',
                },
            ],
        };
    },
    mounted() {
         this.getOrganizationList();
         this.getAddOrgCode();
    },
    methods: {
        addOrg() {
            this.showAddDialog = true;
            this.saveModel.orgName= '';
            this.editFlg= false;
            // this.repository.Organization.GetAddOrgCode().then((res) => {
            //     this.saveModel.orgCode = res.data;
            //});
        },

        UpdOrganization(item){
            this.editFlg = true;
            this.showAddDialog = true;
            this.saveModel = item;
        },

        saveOrganization()
        {
            if(this.saveModel.orgCode=='')
            {
                this.notifier.warn('请输入组织机构编码');
                return;
            }
            if(this.saveModel.orgName=='')
            {
                this.notifier.warn('请输入组织机构名称');
                return;
            }
             this.repository.Organization.AddOrUpdate(
                this.saveModel
            ).then(() => {
                this.notifier.success('保存成功');
                this.showAddDialog = false;
                this.getOrganizationList();
            }).catch(res => {
                this.notifier.error(res.message);
            });
        },
        handleSizeChange() {},
        handleCurrentChange() {},
        getOrganizationList() {
            this.repository.Organization.GetOrganizationInfos().then((res) => {
                this.organizationList = res.data;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.organization {
    width: 80vw;
    margin: 20px auto;
}
.label {
    padding-top: 10px;
    padding-right: 10px;
}
.wrapper-page {
    float: right;
    margin-top: 20px;
}
</style>
