import sender from '../request';

export default class VideoRepository {

    GetMonitorInfo(data) {
        return sender.postWithUnCatch('/Video/GetMonitorInfo', data);
    }

    GetVideos(data) {
        return sender.getWithUnCatch('/Video/GetVideos', data);
    }

}
