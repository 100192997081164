import XLSX from 'xlsx/xlsx';

export default class ExcelReader {
    ReadWorkbook(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function(e) {
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                resolve(workbook);
                reject();
            };
            reader.readAsBinaryString(file);
        });
    }
}