export default class Rules {
    static require(name) {
        return value => !!value || `${name}是必填项！`
    }
    static requireByTip(name) {
        return value => !!value || `${name}！`
    }
    static requireSelect(name) {
        return value => (!!value || value == "0") || `${name}`
    }
    static maxLength(name, maxLength) {
        return value => (value || '').length <= maxLength || `${name}长度最多为${maxLength}个字！`
    }

    static maxLengthWithLimit(name, maxLength) {
        return value => (value || '').length <= maxLength || `${name}长度最多为${maxLength}个字！`
    }
}