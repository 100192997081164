<template>
    <div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate width="8" size="120">
                数据加载中
            </v-progress-circular>
        </v-overlay>
        <v-row no-gutters class="my-1 mr-2">
            <v-col>
                <h3 class="text-center">{{ this.context.districtName }}</h3>
            </v-col>
            <v-col cols="auto">
                <v-btn outlined color="primary" @click="back()">返回</v-btn>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="pa-4">
            <v-data-table
                :headers="[
                    {
                        text: '考点代码',
                        value: 'siteCode',
                        width: '100px',
                    },
                    {
                        text: '考点名称',
                        value: 'name',
                        width: '100px',
                    },

                    {
                        text: '考场数量',
                        value: 'roomCount',
                        width: '100px',
                    },
                    {
                        text: '考点监控进度',
                        value: 'mointoringCount',
                        width: '100px',
                    },
                    {
                        text: '操作',
                        value: 'options',
                        width: '100px',
                    },
                ]"
                :items="siteReports"
                no-data-text="暂无数据"
                :items-per-page="10"
            >
                <template v-slot:item.mointoringCount="{ item }">
                    <replyProcessLinear
                        :value="
                            Percentage(item.mointoringCount, item.totalCount)
                        "
                    ></replyProcessLinear>
                </template>
                <template v-slot:item.options="{ item }">
                    <v-btn
                        @click="ViewSiteDetail(item)"
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                    >
                        详情
                    </v-btn>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import replyProcessLinear from '@/components/replyProcessLinear/replyProcessLinear';
export default
@Component({ components: { replyProcessLinear } })
class EquipmentReports extends Vue {
    static name = window.$views.ExamCsp.ExaminationReport.MonitorSite;
    @Inject()
    repository;
    @Inject()
    notifier;
    @Inject()
    loader;
    @Inject()
    navigation;
    districtCode;
    siteReports = [];
    loading = false;
    mounted() {
        this.districtCode = this.context.districtCode;
        this.GetExaminationSiteReport();
    }

    GetExaminationSiteReport() {
        this.loading = true;
        let roomType = localStorage.getItem('roomType');
        this.repository.ExaminationReport.GetExaminationSiteReport(
            this.districtCode,
            roomType
        ).then((res) => {
            this.siteReports = res.data;
            this.loading = false;
        });
    }

    ViewSiteDetail(item) {
        this.navigation.redirect(
            window.$views.ExamCsp.ExaminationReport.MonitorRoom,
            { siteCode: item.siteCode, siteName: item.name }
        );
    }
    back() {
        this.$router.go(-1);
    }
    Percentage(num, total) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Math.floor((num / total) * 10000) / 100.0;
    }
}
</script>

<style scoped>
</style>
