<!--
 * @Descripttion :
 * @Author       : pishengjun
 * @Date         : 2021-09-24 09:38:45
 * @LastEditors  : pishengjun
 * @FilePath     : \ExamMonitorSystem.ServicePlatform.UI\src\components\videoPlayer\index.vue
-->
<template>
    <div class="videoWrap" :class="{videoFil:enableFil}">
        <div :id="vid || url" class="videoDiv" style="height: 100%; width: 100%"></div>
        <div class="examination-Room--name text-right">
            <slot name="title"></slot>
        </div>
    </div>
</template>

<script>
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

    export default
    @Component
    class VideoPlayerComponent extends Vue {
        @Prop() url;
        @Prop() title;
        @Prop() code;
        @Prop() name;
        @Prop() playerstyle;
        @Prop() vid;
        @Prop() enableFil;

        mounted() {
            /* eslint-disable no-undef */
            const id = this.vid ?? this.url
            this.instance = new TcPlayer(id, {
                m3u8: this.url, //请替换成实际可用的播放地址
                autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                live: true,
                width:'100%',
                height:'100%',
                listener: this.PlayerHandler,
            });

        }

        @Watch('url', { immediate: false, deep: false })
        creatVideo(val) {
            this.instance.load(val)
        }

        PlayerHandler(msg) {
            if (msg.type === 'pause') {
                this.instance.togglePlay();
            }
        }

        beforeDestroy() {
            this.instance.destroy();
            this.instance=null
        }

        destroyed() {
            console.log('+++++++++++++播放器被销毁===>',this.instance)
            this.instance.destroy();
            this.instance=null
            // $('#'+this.url).html("");
        }

        instance = null;
    }
</script>

<style scoped>
    .examination-Room--name {
        width: 100%;
        color: #fff;
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
    }
    .videoDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        height: 100%;
    }
    .videoWrap {
        width: 100%;
        height: 100%;
    }

</style>
<style>
    .videoFil video {
        object-fit: fill !important;
    }
</style>
