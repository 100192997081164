import Storage from './storage';
import keys from './keys';
export default class User {
    static get AccessToken() {
        return Storage.Get(keys.ACCESS_TOKEN);
    }

    static get LoginRoute() {
        return JSON.parse(Storage.Get(keys.LOGIN_ROUTE));
    }

    static get IsLogin() {
        return !!User.AccessToken;
    }

    static get ExamInfo() {
        return Storage.Get(keys.EXAM_INFO);
    }

    static get Role() {
        return Storage.Get(keys.USER_ROLE);
    }

    static get GetInfo() {
        let loginInfoJson = Storage.Get(keys.REGISTRY_INFO);
        if(loginInfoJson!=null&&loginInfoJson!=''){
            return JSON.parse(loginInfoJson);
        }
        else return null;
    }



    static get JurisdictionCheck() {
        let data= Storage.Get(keys.REGISTRY_INFO);
        if(data && JSON.parse(data).onlyLook){
            return false;
        }
        return true;
    }


    static set Role(val) {
        return Storage.Set(keys.USER_ROLE, val);
    }

    static set ExamInfo(val) {
        Storage.Set(keys.EXAM_INFO, val);
    }

    static  AddInfo(val) {
        Storage.Set(keys.REGISTRY_INFO, val);
    }
    static AddAudio(key1,key2,val) {
        Storage.Set(keys.AUDIO_INFO+key1+key2, val);
    }
    static GetAudioInfo(key1,key2) {
        return Storage.Get(keys.AUDIO_INFO+key1+key2);
    }
    static AddReply(val) {
        Storage.Set(keys.REPLAY_INFO, val);
    }
    static GetReplyInfo() {
        return Storage.Get(keys.REPLAY_INFO);
    }
    static Login(token, from) {
        //清除指定的登录缓存
        Storage.RemoveItem(token);
        Storage.Set(keys.ACCESS_TOKEN, token);

        // 保存登录的路由
        Storage.Set(
            keys.LOGIN_ROUTE,
            JSON.stringify({
                name: from.name,
                params: from.params,
                query: from.query,
            })
        );
    }

    static Logout() {
        Storage.RemoveAll();
    }
}
