<template>
    <div class="pa-4">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate width="8" size="120">
                数据加载中
            </v-progress-circular>
        </v-overlay>
        <v-data-table
            :headers="[
                {
                    text: '考区代码',
                    value: 'areaCode',
                    width: '100px',
                },
                {
                    text: '考区名称',
                    value: 'name',
                    width: '100px',
                },
                {
                    text: '考点数量',
                    value: 'siteCount',
                    width: '100px',
                },
                {
                    text: '考区注册进度',
                    value: 'regsiterCount',
                    width: '100px',
                },
                {
                    text: '操作',
                    value: 'options',
                    width: '100px',
                },
            ]"
            :items="equipmentReports"
            no-data-text="暂无数据"
            :items-per-page="10"
            loading-text="Loading... Please wait"
        >
            <template v-slot:item.regsiterCount="{ item }">
                <replyProcessLinear
                    :value="Percentage(item.regsiterCount, item.totalCount)"
                ></replyProcessLinear>
            </template>
            <template v-slot:item.options="{ item }">
                <v-btn
                    @click="ViewDetail(item)"
                    color="primary"
                    small
                    outlined
                    class="mr-2"
                >
                    详情
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import replyProcessLinear from '@/components/replyProcessLinear/replyProcessLinear';
export default
@Component({ components: { replyProcessLinear } })
class EquipmentReports extends Vue {
    static name = window.$views.ExamCsp.ExaminationReport.RegsiterDistrict;
    @Inject()
    repository;
    @Inject()
    notifier;
    @Inject()
    loader;
    @Inject()
    navigation;
    loading = false;
    equipmentReports = [];

    async mounted() {
        await this.GetExaminationAreaReport();
    }

    GetExaminationAreaReport() {
        this.loading = true;
        let roomType = localStorage.getItem('roomType');
        this.repository.ExaminationReport.GetExaminationAreaReport(
            roomType
        ).then((res) => {
            this.equipmentReports = res.data;
            this.loading = false;
        });
    }

    ViewDetail(item) {
        this.navigation.redirect(
            window.$views.ExamCsp.ExaminationReport.RegsiterSite,
            {
                districtCode: item.areaCode,
                districtName: item.name,
            }
        );
    }

    Percentage(num, total) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Math.floor((num / total) * 10000) / 100.0;
    }
}
</script>
<style scoped>
</style>
