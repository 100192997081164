import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.min.css';
import zhHans from 'vuetify/es5/locale/zh-Hans';
import '@/assets/css/override.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
      locales: { zhHans },
      current: 'zhHans',
    },
    theme: {
      themes: {
          light: {
              primary: '#3D9AFE',
          },
      },
  },
});
