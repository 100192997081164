<template>
    <div>
        <div class="login-wrapper">
            <v-btn color="primary" @click="loginOut" dark v-bind="attrs" v-on="on">
                退出登录
            </v-btn>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    inject: ['navigation', 'user'],
    data() {
        return {
            items: [{ title: '退出登录' }],
        };
    },
    methods: {
        loginOut() {
            const roomType = localStorage.getItem('roomType');
            this.user.Logout();
            roomType == -1
                ? this.navigation.redirect(window.$views.Login)
                : this.navigation.redirect(window.$views.Login, {
                      roomType: roomType,
                  });
        },
    },
};
</script>
<style>
.login-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 80vw;
    margin: 20px auto;
}
</style>
