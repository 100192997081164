import XLSX from 'xlsx/xlsx';

export default class Workbook {
    _sheets = {};
    _sheetNames = [];
    _options = {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary',
    };

    AddDefaultSheet(sheet) {
        this.AddSheet('Sheet1', sheet);
    }

    AddSheet(name, sheet) {
        this._sheetNames.push(name);
        this._sheets[name] = sheet;
    }

    AsBlob() {
        let source = {
            SheetNames: this._sheetNames,
            Sheets: this._sheets,
        };
        let str = XLSX.write(source, this._options);
        return new Blob([this.str2ArrayBinary(str)], {
            type: 'application/octet-stream',
        });
    }

    str2ArrayBinary(source) {
        let bufs = new ArrayBuffer(source.length);
        let view = new Uint8Array(bufs);
        for (let i = 0; i < source.length; i++) {
            view[i] = source.charCodeAt(i) & 0xff;
        }
        return bufs;
    }
}
