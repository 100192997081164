import notifier from '../../components/notification';
import router from '@/router';
import user from '@/domain/user';
import loader from '../../components/loader';

export default class ExceptionExtension {
    static HttpExceptionHandler(data) {
        if ('code' in data) {
            switch (data.code) {
                case 600:
                    notifier.warn('未登录, 请通过登录页面登录系统.');
                    ExceptionExtension.RedirectLoginPage();
                    break;
                case 601:
                    notifier.warn('登录过期, 请重新登录.');
                    break;
                case 602:
                    notifier.warn(`无权限访问.`);
                    break;
                case 500:
                    notifier.warn(data.message);
                    break;
                default:
                    notifier.error('请求数据失败, 请重试.');
            }
        } else {
            notifier.error(`请求数据失败,请重试.`);
        }
    }

    static ApplicationExceptionHandler() {
        loader.CloseAll();
    }

    static RedirectLoginPage() {
        router.replace(user.LoginRoute);
    }
}
