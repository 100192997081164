import Enum from '@/domain/enums/Enum';
import EnumItem from '@/domain/enums/EnumItem';

export default class ApprovalStatus extends Enum {
    _待审批 = new EnumItem('待审批', 0);
    _审批通过 = new EnumItem('审批通过', 1);
    _审批不通过 = new EnumItem('审批不通过', 2);

    get 待审批() {
        return this._待审批;
    }

    get 审批通过() {
        return this._审批通过;
    }

    get 审批不通过() {
        return this._审批不通过;
    }

}
