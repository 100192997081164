<template>
    <div class="login-bg">
        <v-form ref="form">
            <div class="header-title">
                <v-card max-width="420" class="mx-auto py-5 login-card">
                    <v-row align="center">
                        <v-col>
                            <p class="text-center headline">
                                卓帆视频监控云服务平台
                            </p>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="10" class="text-center">
                            <div class="d-flex">
                                <v-icon>mdi-account</v-icon>
                                <v-text-field
                                    v-model="loginInfo.account"
                                    placeholder="请输入您的账号"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="d-flex">
                                <v-icon>mdi-lock</v-icon>
                                <v-text-field
                                    v-model="loginInfo.password"
                                    type="password"
                                    placeholder="请输入您的密码"
                                    required
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="10">
                            <v-btn
                                block
                                color="primary"
                                class="white--text title login-card"
                                @click="login"
                                large
                            >
                                登录
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </v-form>
    </div>
</template>


<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
export default
@Component
class Login extends Vue {
    static name = window.$views.Login;
    @Inject()
    repository;
    @Inject()
    navigation;
    @Inject()
    notifier;
    @Inject()
    user;
    @Inject()
    enums;

    loginInfo = { account: '', password: '',OrganizationId :'' };

    login() {
        var roomType = this.$route.query.roomType;
        if (!roomType) {
            roomType = -1; //如果不带参数默认取全部
        }
        localStorage.setItem('roomType', roomType);
        if (this.loginInfo.account == '') {
            this.notifier.warn(`请输入账号`);
            return;
        }

        if (this.loginInfo.password == '') {
            this.notifier.error(`请输入密码`);
            return;
        }
        this.loginInfo.OrganizationId = this.context.organization;
        this.repository.User.Login(this.loginInfo)
            .then((res) => {
                let token = res.data.split('&')[0];
                let areaid = res.data.split('&')[1];
                let areaname = res.data.split('&')[2];
                let usertype = res.data.split('&')[3];
                let orgName = res.data.split('&')[4];
                let organizationId = res.data.split('&')[5];
                //保存token
                this.user.Login(token, this.$router.currentRoute);
                let userObj = {};
                userObj.id = areaid;
                userObj.name = areaname;
                userObj.type = usertype;
                userObj.organization = organizationId;
                if (usertype == this.enums.UserType.管理员.value) {
                    this.navigation.redirect(
                        window.$views.Organization.List.Index
                    );
                }
                else if(usertype == this.enums.UserType.组考机构管理员.value){
                    localStorage.setItem('orgName',orgName);
                    this.navigation.redirect(
                        window.$views.ExamCsp.ExaminationDistrict.List
                    );
                }
                //只读权限先隐藏
                // else if (areaname == 'monitorAdmin') {
                //     userObj.type = this.enums.UserType.管理员.value;
                //     userObj.onlyLook = true;
                //     this.navigation.redirect(
                //         window.$views.ExamCsp.ExaminationDistrict.List
                //     );
                // }
                else if(usertype == this.enums.UserType.考区管理员.value){
                    this.navigation.redirect(
                        window.$views.ExaminationAdmin.ExaminationSite.List,{
                            code: this.loginInfo.account
                        }
                    );
                }
                this.user.AddInfo(JSON.stringify(userObj));
                this.notifier.success('登录成功');
            })
            .catch((error) => {
                this.notifier.error(`登录失败: ${error.message}`);
            });
    }
}
</script>


<style scoped>
.login-bg {
    width: 100%;
    height: 100%;
    background: url('../assets/login.jpg') center bottom no-repeat;
    background-size: 100% auto;
}

.header-title {
    margin-top: 13%;
    margin-bottom: 50px;
}

.login-card {
    border-radius: 20px;
}
</style>
