import Vue from 'vue';
import VueRouter from 'vue-router';
import Generator from 'ea-router';
import views from '../domain/views';
Vue.use(VueRouter);

window.$views = views;
Vue.prototype.$views = views;

let generator = new Generator(require.context('../views', true, /\.vue$/));
let routes = generator.generate();

const router = new VueRouter({
  routes: [routes],
});


export default router;
