<template>
    <div>
        {{count}}
        <v-btn @click="click">click</v-btn>
    </div>
</template>

<script>
    import {Vue, Component, Inject} from 'vue-property-decorator';




    export default
    @Component
    class DemoPage2 extends Vue{
        name = "ClassComponent2";

        @Inject()
        notifier;

        @Inject()
        navigation;



        @Inject()
        repository;

        count = 1;

         click() {
            this.navigation.redirect(
                this.$views.ExaminationRoomMonitor.Index,
                {roomId:1005}
            );
        }
    }
</script>
