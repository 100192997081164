import sender from '../request';
export default class UserRepository {

    GetUserInfo(organizationId) {
        return sender.get("/User/GetUserInfo",{organizationId:organizationId})
    }

    AddOrUpdate(data) {
        return sender.new_post("/User/AddOrUpdate", data)
    }

    GetUsers(data) {
        return sender.get("/User/GetUsers", data)
    }

    SetPassword(data) {
        return sender.postWithUnCatch("/User/SetPassword", data)
    }

    Login(data) {
        return sender.postWithUnCatch("/User/Login", data)
    }
}
