export default class Storage {
    static Get(key) {
        return localStorage.getItem(key);
    }

    static Set(key, value) {
        localStorage.setItem(key, value);
    }

    static RemoveAll() {
        localStorage.clear();
    }
    static RemoveItem(key) {
        localStorage.removeItem(key);
    }
}
