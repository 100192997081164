import Enum from '@/domain/enums/Enum';
import EnumItem from '@/domain/enums/EnumItem';

export default class DistrictRecruitmentStatus extends Enum {
    _待补充完成补充考生信息 = new EnumItem('待补充完成补充考生信息', 0);
    _完成补充考生信息 = new EnumItem('完成补充考生信息', 1);
    _提交审批中 = new EnumItem('提交审批中', 2);
    _已审批 = new EnumItem('已审批', 3);

    get 待补充完成补充考生信息() {
        return this._待补充完成补充考生信息;
    }

    get 完成补充考生信息() {
        return this._完成补充考生信息;
    }

    get 提交审批中() {
        return this._提交审批中;
    }

    get 已审批() {
        return this._已审批;
    }
}
