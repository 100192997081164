import Enum from '@/domain/enums/Enum';
import EnumItem from '@/domain/enums/EnumItem';

export default class UserType extends Enum {

    _考区 = new EnumItem('考区', 1);
    _考点 = new EnumItem('考点', 2);
    _考场 = new EnumItem('考点', 3);
    get 考区() {
        return this._考区;
    }
    get 考点() {
        return this._考点;
    }
    get 考场 () {
        return this._考场 ;
    }

}
