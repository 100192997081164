<template>
    <div>
        <v-row no-gutters class="my-1">
            <v-col>
                <h3 class="text-center">{{ this.areaName }}</h3>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="pa-4">
            <v-row class="d-flex mb-n4 mt-3" no-gutters>
                <v-col class="ml-3">
                    <div class="d-flex">
                        <div class="input-width mr-2">
                            <v-text-field
                                v-model="searchParam.code"
                                placeholder="请输入考点代码查询"
                                dense
                                outlined
                            ></v-text-field>
                        </div>
                        <v-btn color="primary" @click="GetExaminationSites">
                            查询
                        </v-btn>
                        <v-btn
                            color="primary"
                            class="ml-2"
                            @click="addExaminationSiteClick"
                        >
                            新增考点
                        </v-btn>
                        <v-btn   class="ml-2" color="primary" @click="allLookMonitor">
                            查看监控
                        </v-btn>
                    </div>
                </v-col>
            </v-row>

            <v-data-table
                :headers="[
                    {
                        text: '考点代码',
                        value: 'code',
                        width: '100px',
                    },
                    {
                        text: '考点名称',
                        value: 'name',
                        width: '100px',
                    },
                    {
                        text: '操作',
                        value: 'options',
                        width: '100px',
                    },
                ]"
                :items="examinationSites"
                no-data-text="暂无数据"
                :items-per-page="10"
            >
                <template v-slot:item.options="{ item }">
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        @click="lookMonitor(item)"
                    >
                        查看监控
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        :to="
                            $to($views.ExaminationAdmin.ExaminationRoom.List, {
                                code: item.code,
                                name: item.name,
                                parentCode: $route.query.code,
                            })
                        "
                    >
                        考场管理
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        @click="UpdateClick(item)"
                    >
                        修改
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        @click="deleteClick(item.id)"
                    >
                        删除
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <v-dialog max-width="500" persistent v-model="addExaminationSiteDialog">
            <v-card>
                <v-card-title class="headline primary white--text">
                    {{ this.filedName }}
                </v-card-title>
                <div class="d-flex mt-4 mx-6">
                    <label class="mt-2">考点代码：</label>
                    <v-text-field
                        v-model="addExaminationSite.code"
                        placeholder="请输入考点代码"
                        dense
                        required
                        outlined
                    />
                </div>
                <div class="d-flex mt-4 mx-6">
                    <label class="mt-2">考点名称：</label>
                    <v-text-field
                        v-model="addExaminationSite.name"
                        placeholder="请输入考点名称"
                        dense
                        required
                        outlined
                    />
                </div>
                <v-divider></v-divider>
                <div class="text-center py-2">
                    <v-btn e2e-save-city @click="save" color="primary">
                        保存
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="ml-2"
                        outlined
                        @click="addExaminationSiteDialog = false"
                    >
                        取消
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Storage from '@/domain/storage';
import keys from '@/domain/keys';

export default {
    name: window.$views.ExaminationAdmin.ExaminationSite.List,
    inject: ['repository', 'navigation', 'notifier'],
    data() {
        return {
            areacode: '',
            examinationSites: [],
            searchParam: { code: '', areacode: '' },
            addExaminationSite: { id: '', code: '', name: '' },
            addExaminationSiteDialog: false,
            filedName: '新增考点',
            areaName: '',
        };
    },
    created() {
        if (
            Storage.Get(keys.REGISTRY_INFO) != undefined &&
            Storage.Get(keys.REGISTRY_INFO) != null
        ) {
            this.areaName = JSON.parse(Storage.Get(keys.REGISTRY_INFO)).name;
        }
    },
    computed: {
        type() {
            return this.context.type;
        },
        code (){
            return this.$route.query.code;
        }
    },
    mounted() {
        this.areacode = this.context.areacode;
        this.GetExaminationSites();
    },
    methods: {
        allLookMonitor() {
            this.navigation.redirect(
                this.$views.Monitor.ExaminationSiteMonitor.Index,
                {
                    from: 'city',
                    areacode: '',
                    type: 2,
                    leftMenu: true,
                    code: this.code,
                    parentCode: this.$route.query.code,
                }
            );
        },
        lookMonitor(item) {
            this.navigation.redirect(
                this.$views.Monitor.ExaminationSiteMonitor.Index,
                {
                    code: item.code,
                    parentCode: this.$route.query.code,
                    areacode: this.context.areacode,
                    type: this.context.type,
                    isflag: true
                }
            );
        },
        GetExaminationSites() {
            let roomType = localStorage.getItem('roomType');
            this.searchParam.areacode = this.areacode;
            this.searchParam.type = this.type;
            this.searchParam.roomType = roomType;
            this.repository.ExaminationSite.GetExaminationSites(
                this.searchParam
            ).then((res) => {
                this.examinationSites = res.data;
            });
        },

        save() {
            this.AddOrUpdate();
        },

        addExaminationSiteClick() {
            this.addExaminationSiteDialog = true;
            this.filedName = '新增考点';
            this.isAdd = true;
            this.addExaminationSite = {};
        },

        UpdateClick(item) {
            this.filedName = '修改考点';
            this.addExaminationSiteDialog = true;
            this.addExaminationSite.id = item.id;
            this.addExaminationSite.name = item.name;
            this.addExaminationSite.code = item.code;
        },

        async AddOrUpdate() {
            let result =
                await this.repository.ExaminationDistrict.GetExistExaminationArea(
                    this.addExaminationSite.code
                );
            if (!result.data) {
                this.notifier.warn('输入的考点代码不正确');
                return;
            }
            this.repository.ExaminationSite.AddOrUpdate(
                this.addExaminationSite
            ).then(() => {
                this.addExaminationSiteDialog = false;
                this.notifier.success('保存成功');
                this.GetExaminationSites();
            });
        },

        deleteClick(id) {
            let roomType = localStorage.getItem('roomType');
            this.$confirm('提示', `确定要删除吗？`).then(() => {
                this.repository.ExaminationSite.Delete({
                    id: id,
                    roomType: roomType,
                }).then(() => {
                    this.notifier.success('删除成功');
                    this.GetExaminationSites();
                });
            });
        },
    },
};
</script>
