var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"my-1",attrs:{"no-gutters":""}},[_c('v-col',[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(this.context.name))])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.back}},[_vm._v("返回")])],1)],1),_c('v-divider'),_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"input-width mr-2"},[_c('v-text-field',{attrs:{"placeholder":"请输入考场代码查询","dense":"","outlined":""},model:{value:(_vm.searchParam.centreNo),callback:function ($$v) {_vm.$set(_vm.searchParam, "centreNo", $$v)},expression:"searchParam.centreNo"}})],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getExaminationrooms}},[_vm._v("查询")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":_vm.addCentreNoClick}},[_vm._v(" 新增 ")])],1),_c('v-data-table',{attrs:{"headers":[
                {
                    text: '考场代码',
                    value: 'centreNo',
                    width: '100px',
                },
                {
                    text: '考场名称',
                    value: 'name',
                    width: '100px',
                },
                {
                    text: '操作',
                    value: 'options',
                    width: '100px',
                } ],"items":_vm.examinationrooms,"no-data-text":"暂无数据","items-per-page":10},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.RoomMonitorView(item)}}},[_vm._v(" 查看监控 ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":"","outlined":"","to":_vm.$to(
                            _vm.$views.ExaminationAdmin.ExaminationRoom.Details,
                            {
                                centreNo: item.centreNo,
                                name: item.name,
                            }
                        )}},[_vm._v(" 详情 ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.UpdateClick(item)}}},[_vm._v(" 修改 ")]),_c('v-btn',{attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.deleteClick(item)}}},[_vm._v(" 删除 ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.centreNoDialog),callback:function ($$v) {_vm.centreNoDialog=$$v},expression:"centreNoDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary white--text"},[_vm._v(" "+_vm._s(this.filedName)+" ")]),_c('div',{staticClass:"d-flex mt-4 mx-6"},[_c('label',{staticClass:"mt-2"},[_vm._v("考场代码：")]),_c('v-text-field',{attrs:{"placeholder":"请输入考场代码","dense":"","required":"","outlined":""},model:{value:(_vm.addCentreNo.centreNo),callback:function ($$v) {_vm.$set(_vm.addCentreNo, "centreNo", $$v)},expression:"addCentreNo.centreNo"}})],1),_c('div',{staticClass:"d-flex mx-6"},[_c('label',{staticClass:"mt-2"},[_vm._v("考场名称：")]),_c('v-text-field',{attrs:{"placeholder":"请输入考场名称","dense":"","required":"","outlined":""},model:{value:(_vm.addCentreNo.name),callback:function ($$v) {_vm.$set(_vm.addCentreNo, "name", $$v)},expression:"addCentreNo.name"}})],1),_c('div',{staticClass:"text-center py-2"},[_c('v-btn',{attrs:{"e2e-save-city":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" 保存 ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.centreNoDialog = false}}},[_vm._v(" 取消 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }