<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>
import repository from './infrastructures/repositories';
import navigation from './infrastructures/navigation';
import enums from './domain/enums';
import notifier from './components/notification';
import loader from './components/loader';
import user from './domain/user';
import rules from './infrastructures/rules';

export default {
    provide() {
        return {
            repository: repository,
            enums: enums,
            navigation: new navigation(this.$router),
            notifier: notifier,
            loader: loader,
            user: user,
            rules: rules,
        };
    },
};
</script>
