import sender from '../request';
export default class OrganizationRepository {

    GetOrganizationInfos() {
        return sender.get("/Organization/GetOrganizationInfos")
    }

    AddOrUpdate(data) {
        return sender.postWithUnCatch("/Organization/AddOrUpdate", data)
    }

    Delete(orgId) {
        return sender.postWithUnCatch("/Organization/Delete" , {id:orgId})
    }

    GetAddOrgCode() {
        return sender.get("/Organization/GetAddOrgCode")
    }
}
