<template>
    <v-progress-linear
        class="process-wrap"
        :value="process"
        :color="curProcessColor"
        height="15"
    >
        <span :class="curTextColor"> {{Math.floor(process) }}% </span>
    </v-progress-linear>
</template>

<script>
    export default {
        props: {
            value: {
                Number,
                default: 0,
            },
            finishedTextColor: {
                String,
                default: 'white--text',
            },
            textColor: {
                String,
                default: 'black--text',
            },
            finishedColor: {
                String,
                default: 'success',
            },
            color: {
                String,
                default: 'primary',
            },
        },
        computed: {
            curTextColor() {
                if (this.finished) return this.finishedTextColor;
                return this.textColor;
            },
            curProcessColor() {
                if (this.finished) return this.finishedColor;
                return this.color;
            },
            noData() {
                return this.process <= 0;
            },
            finished() {
                return this.process >= 100;
            },
        },
        watch: {
            value(val) {
                this.process = val;
            },
        },
        data() {
            return {
                process: this.value,
            };
        },
    };
</script>

<style scoped>
    .process-wrap {
        z-index: 0;
    }
</style>
