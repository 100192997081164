<template>
    <div>
        <v-form ref="form" v-model="valid" lazy-validation>
            <div class="mx-auto pt-5 pb-5 editInfo-content">
                <v-row align="center">
                    <v-col>
                        <p class="text-center title font-weight-bold">
                            修改组考机构信息
                        </p>
                    </v-col>
                </v-row>
               <v-row>
                    <v-col cols="auto">
                        <label class="label subtitle-1">机构名称：</label>
                    </v-col>
                    <v-col>
                        <v-text-field
                            placeholder="请输入机构名称"
                            type="text"
                            v-model="organization.name"
                            solo
                            dense
                            required
                        />
                    </v-col>
                </v-row>
                 <v-row>
                    <v-col cols="auto">
                        <label class="label subtitle-1">管理员姓名：</label>
                    </v-col>
                    <v-col cols="4" class="ml-n4">
                        <v-text-field
                            v-model="organization.adminTrueName"
                            placeholder="请输入管理员姓名"
                            type="text"
                            solo
                            dense
                            required
                        />
                    </v-col>
                </v-row>
                 <v-row>
                    <v-col cols="auto">
                        <label class="label subtitle-1">联系电话：</label>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="organization.adminContact"
                            placeholder="请输入联系电话"
                            type="text"
                            solo
                            dense
                            required
                        />
                    </v-col>
                </v-row>
                  <v-row>
                    <v-col cols="auto">
                        <label class="label subtitle-1">监控画面数量:</label>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            type="Number"
                            min="0"
                            oninput="if(value<0)value=0"
                            solo
                            dense
                            v-model="organization.monitoringPicQuantity"
                        ></v-text-field>
                    </v-col>
                </v-row>
                 <v-row no-gutters>
                    <v-col cols="auto" class="mt-4 mr-2">
                        <label class="label subtitle-1">是否开启音频:</label>
                    </v-col>
                    <v-col>
                        <v-switch v-model="organization.enableAudio"></v-switch>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="auto" class="mt-4 mr-2">
                        <label class="label subtitle-1">是否开启面试:</label>
                    </v-col>
                    <v-col>
                        <v-switch v-model="organization.isInterview"></v-switch>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="auto" class="mt-4 mr-2">
                        <label class="label subtitle-1">
                            是否开启语音通话:
                        </label>
                    </v-col>
                    <v-col>
                        <v-switch v-model="organization.isVoiceCall"></v-switch>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="auto" class="mt-4 mr-2">
                        <label class="label subtitle-1">
                            是否开启桌面推流:
                        </label>
                    </v-col>
                    <v-col>
                        <v-switch
                            v-model="organization.isDeskRecord"
                        ></v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="auto">
                        <label class="label subtitle-1">Logo：</label>
                    </v-col>

                    <v-col v-if="image == ''">
                        <input
                            type="file"
                            name="logofile"
                            accept=".jpg, .png, .jpeg"
                            @v-model="file"
                            style="display: none"
                            ref="fileInput"
                            @change="chooseFile"
                        />
                        <v-card
                            flat
                            max-width="740"
                            class="editInfo-content--cursor"
                        >
                            <v-img
                                class="img-radius text-center"
                                max-width="740"
                                height="100"
                                @click="$refs.fileInput.click()"
                            >
                                <p class="mt-7">
                                    <v-icon large>mdi-plus</v-icon>
                                </p>
                            </v-img>
                        </v-card>
                    </v-col>
                    <v-col v-else>
                        <input
                            type="file"
                            name="logofile"
                            accept=".jpg, .png, .jpeg"
                            @v-model="file"
                            style="display: none"
                            ref="fileInput"
                            @change="chooseFile"
                        />
                        <v-hover v-slot:default="{ hover }">
                            <v-card flat max-width="730">
                                <v-img
                                    :src="image"
                                    ref="imgSnap"
                                    class="text-center img-radius"
                                    max-width="730"
                                    height="100"
                                    @click="$refs.fileInput.click()"
                                >
                                    <v-expand-transition>
                                        <div
                                            v-if="hover"
                                            class="
                                                d-flex
                                                grey
                                                darken-1
                                                v-card--reveal
                                                white--text
                                            "
                                            style="height: 100%"
                                        >
                                            替换图片
                                        </div>
                                    </v-expand-transition>
                                </v-img>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
                <v-row class="ml-10">
                    <p class="ml-7 red--text">
                        支持.jpg, .png, .jpeg格式，适用尺寸为1070*170
                    </p>
                </v-row>
                <v-row>
                    <v-col cols="auto">
                        <label class="label subtitle-1">图标：</label>
                    </v-col>
                    <v-col class="mt-3" v-if="iconimage == ''">
                        <v-card
                            flat
                            max-width="100"
                            class="editInfo-content--cursor"
                        >
                            <v-img
                                class="img-radius text-center"
                                max-width="100"
                                height="100"
                                @click="$refs.iconfileInput.click()"
                            >
                                <p class="mt-7">
                                    <v-icon large>mdi-plus</v-icon>
                                </p>
                            </v-img>
                        </v-card>
                        <input
                            type="file"
                            name="photo"
                            accept=".ico"
                            multiple
                            @v-model="iconfile"
                            style="display: none"
                            ref="iconfileInput"
                            @change="chooseIconFile"
                        />
                    </v-col>
                    <v-col class="mt-3" v-else>
                        <v-hover v-slot:default="{ hover }">
                            <v-card flat max-width="100">
                                <v-img
                                    class="img-radius"
                                    @click="$refs.iconfileInput.click()"
                                    max-width="100"
                                    height="100"
                                    :src="iconimage"
                                >
                                    <v-expand-transition>
                                        <div
                                            v-if="hover"
                                            class="
                                                d-flex
                                                grey
                                                darken-1
                                                v-card--reveal
                                                white--text
                                            "
                                            style="height: 100%"
                                        >
                                            替换图片
                                        </div>
                                    </v-expand-transition>
                                </v-img>
                            </v-card>
                        </v-hover>
                        <input
                            type="file"
                            name="photo"
                            accept=".ico"
                            multiple
                            @v-model="iconfile"
                            style="display: none"
                            ref="iconfileInput"
                            @change="chooseIconFile"
                        />
                    </v-col>
                </v-row>
                <v-row class="ml-10">
                    <p class="ml-7 red--text">支持.ico，适用尺寸为32*32</p>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="auto" class="mt-4 mr-2">
                        <label class="label subtitle-1">
                            是否显示机构名称:
                        </label>
                    </v-col>
                    <v-col>
                        <v-switch v-model="organization.isShowName"></v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="auto">
                        <label class="label subtitle-1">服务支撑:</label>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="organization.serviceSupport"
                            placeholder="请输入服务支撑单位信息"
                            type="text"
                            solo
                            dense
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="auto">
                        <label class="label subtitle-1">技术支持:</label>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="organization.technologySupport"
                            value="深圳市卓帆技术有限公司  电话：0755-26681864"
                            placeholder="请输入技术支持单位信息"
                            type="text"
                            solo
                            dense
                            required
                        />
                    </v-col>
                </v-row>
                <div class="t_center mt-2">
                    <v-btn color="primary" large @click="submit">保存</v-btn>
                    <v-btn color="primary" large outlined @click="cancel">
                        取消
                    </v-btn>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
export default {
    name: window.$views.Organization.BaseConfig,
    inject: ['navigation', 'notifier', 'repository', 'rules', 'enums'],
    data() {
        return {
            file: '',
            iconfile: '',
            valid: true,
            image: '',
            iconimage: '',
            organization: {
                name: '',
                logoUrl: '',
                iconFileUrl: '',
                serviceSupport: '',
                technologySupport: '',
                roomMonitorNum: 12,
            },
        };
    },
    async created() {
        this.repository.Organization.Info(this.context.organization).then(
            (res) => {
                this.organization = res.data;
                this.image =
                    this.organization.logoUrl == null
                        ? ''
                        : this.organization.logoUrl;
                this.iconimage =
                    this.organization.iconFileUrl == null
                        ? ''
                        : this.organization.iconFileUrl;

                this.organization.logoUrl =
                    this.organization.logoUrl == null
                        ? ''
                        : this.organization.logoUrl;
                this.organization.iconFileUrl =
                    this.organization.iconFileUrl == null
                        ? ''
                        : this.organization.iconFileUrl;
                this.organization.adminContact =
                    this.organization.adminContact == null
                        ? ''
                        : this.organization.adminContact;
                this.organization.adminTrueName =
                    this.organization.adminTrueName == null
                        ? ''
                        : this.organization.adminTrueName;
                this.organization.serviceSupport =
                    this.organization.serviceSupport == null
                        ? ''
                        : this.organization.serviceSupport;
                this.organization.technologySupport =
                    this.organization.technologySupport == null
                        ? '深圳市卓帆技术有限公司  电话：0755-26681864'
                        : this.organization.technologySupport;
            }
        );
    },
    methods: {
        cancel() {
            this.$router.back(-1);
        },
        chooseFile(event) {
            let that = this;
            this.file = this.getFile(event, function (file) {
                that.image = file;
            });
        },
        chooseIconFile(event) {
            let that = this;
            this.iconfile = this.getFile(event, function (file) {
                that.iconimage = file;
            });
        },
        getFile(event, fileResult) {
            const files = event.target.files;
            let fileName = files[0].name;
            if (fileName.lastIndexOf('.') <= 0) {
                this.notifier.error('请选择一张图片');
            }
            const fileReader = new FileReader();
            fileReader.addEventListener('load', () => {
                fileResult(fileReader.result);
            });
            fileReader.readAsDataURL(files[0]);
            let formData = new FormData();
            formData.append('file', files[0]);
            let fileSize = files[0].size;
            if (fileSize / (1024 * 1024) > 2) {
                this.notifier.error('文件大小不能超过2M');
            }
            return files[0];
        },
        submit() {
            if (this.$refs.form.validate() == false) return;
            this.organization.organizationId = this.context.organization;

            console.log('organization', this.organization.technologySupport);
            this.repository.Organization.UpdateInfo(
                [
                    { name: 'file', content: this.file },
                    { name: 'iconfile', content: this.iconfile },
                ],
                this.organization
            )
                .then(() => {
                    this.notifier.success('修改成功！');
                })
                .catch((res) => {
                    console.log(111);
                    this.notifier.error(`${res.message}`);
                });
        },
    },
};
</script>
<style scoped>
.editInfo-content {
    max-width: 810px;
}
.img-radius {
    border: 1px solid #cdd1d4;
    border-radius: 5px;
}
.v-card--reveal {
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    position: absolute;
    width: 100%;
    transition: opacity 0.4s ease-in-out;
    cursor: pointer;
    color: #fff;
}
.editInfo-content--cursor {
    cursor: pointer;
}
</style>
