<template>
    <div :class="pageClass" style="height: 100%">
        <v-window v-model="state">
            <v-window-item :value="0">
                <v-alert class="tip-dialog mx-auto primary">
                    参数校验中...
                    <template #prepend>
                        <v-progress-circular
                            class="mr-2"
                            indeterminate
                        ></v-progress-circular>
                    </template>
                </v-alert>
            </v-window-item>
            <v-window-item :value="1">
                <monitor-view
                    v-if="ready"
                    :width="width"
                    :height="height"
                    :title-handler="GetExaminationSiteData"
                    :page-handler="GetOnlineEquipmentCount"
                    :page-data-handler="GetOnlineEquipments"
                ></monitor-view>
            </v-window-item>
            <v-window-item :value="2">
                <v-alert class="tip-dialog mx-auto" prominent type="error">
                    <v-row align="center">
                        <v-col class="grow">
                            {{ msg }}
                        </v-col>
                    </v-row>
                </v-alert>
            </v-window-item>
        </v-window>
    </div>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import MonitorView from '@/components/MonitorView/Extend';

export default
@Component({
    components: {
        MonitorView,
    },
})
class MonitorViewPageComponent extends Vue {
    @Inject() repository;
    @Inject() notifier;

    get token() {
        return this.context.token;
    }

    get code() {
        return this.context.code;
    }

    get valid() {
        return this.token && this.code;
    }

    get ready() {
        return this.width && this.height;
    }

    async updated() {
        await this.$nextTick(() => {
            if (!this.width) {
                this.width = this.$el.clientWidth;
            }

            if (!this.height) {
                this.height = this.$el.clientHeight;
            }
        });
    }

    async mounted() {
        if (this.valid) {
            await this.Init();
        } else {
            this.LoadError('无有效参数.');
        }
    }

    async Init() {
        let result = await this.repository.ExaminationRoom.VerifyToken(
            this.code,
            this.token
        );
        if (result) {
            this.LoadSuccess();
        } else {
            this.LoadError('参数校验失败.');
        }
    }

    async GetOnlineEquipmentCount() {
        let roomType = localStorage.getItem('roomType');
        let response =
            await this.repository.ExaminationRoom.GetOnlineEquipmentCount({
                groupNo: this.code,
                roomType: roomType,
            });
        return response.data;
    }

    async GetOnlineEquipments(page) {
        let response =
            await this.repository.ExaminationRoom.GetOnlineEquipments({
                groupNo: this.code,
                pageSize: page.size,
                page: page.cur,
            });
        return response.data;
    }

    async GetExaminationSiteData() {
        let roomType = localStorage.getItem('roomType');
        let response =
            await this.repository.ExaminationSite.GetExaminationSites({
                code: this.code,
                type: 1,
                roomType: roomType,
            });
        return response.data;
    }

    LoadSuccess() {
        this.state = 1;
        this.pageClass = 'black';
    }

    LoadError(msg) {
        this.state = 2;
        this.msg = msg;
    }

    state = 0; // 0: 加载中, 1: 加载完成, 2: 错误

    msg = '';
    pageClass = '';

    width = null;
    height = null;
}
</script>

<style type="text/css" scoped>
.tip-dialog {
    top: 200px;
    width: 300px;
}
</style>
