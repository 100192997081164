import axios from 'axios';
import config from '@/config';
import Loader from '../../components/loader';

class RequestSender {
    static ErrorHandler = null;
    static TokenGetter = null;
    static get(url, params, timeout = 10000) {
        return RequestSender._getRequest({
            url: RequestSender._getUrl(url),
            baseURL: '',
            headers: {
                AccessToken: RequestSender.TokenGetter
                    ? RequestSender.TokenGetter()
                    : '',
            },
            params: params,
            timeout: timeout,
        });
    }

    static getWithUnCatch(url, params, timeout = 300000) {
        return RequestSender._getRequestWithUnCatch({
            url: RequestSender._getUrl(url),
            baseURL: '',
            headers: {
                AccessToken: RequestSender.TokenGetter
                    ? RequestSender.TokenGetter()
                    : '',
            },
            params: params,
            timeout: timeout,
        });
    }

    static new_get(url, params, timeout = 10000) {
        return new Promise((resolve, reject) => {
            RequestSender._getRequest({
                url: RequestSender._getUrl(url),
                baseURL: '',
                headers: {
                    AccessToken: RequestSender.TokenGetter
                        ? RequestSender.TokenGetter()
                        : '',
                },
                params: params,
                timeout: timeout,
            })
                .then(res => {
                    if (res.code === 200) resolve(res.data);
                    else reject(res);
                })
                .catch(error => {
                    if (RequestSender.ErrorHandler) {
                        RequestSender.ErrorHandler(error);
                        Loader.CloseAll();
                    }
                });
        });
    }

    static post(url, data, timeout = 10000) {
        return RequestSender._getRequest({
            method: 'post',
            url: RequestSender._getUrl(url),
            baseURL: '',
            headers: {
                AccessToken: RequestSender.TokenGetter
                    ? RequestSender.TokenGetter()
                    : '',
            },
            data: data,
            timeout: timeout,
        });
    }

    static postWithUnCatch(url, data, timeout = 10000) {
        return RequestSender._getRequestWithUnCatch({
            method: 'post',
            url: RequestSender._getUrl(url),
            baseURL: '',
            headers: {
                AccessToken: RequestSender.TokenGetter
                    ? RequestSender.TokenGetter()
                    : '',
            },
            data: data,
            timeout: timeout,
        });
    }

    static new_post(url, data, timeout = 10000) {
        return new Promise((resolve, reject) => {
            RequestSender._getRequest({
                method: 'post',
                url: RequestSender._getUrl(url),
                baseURL: '',
                headers: {
                    AccessToken: RequestSender.TokenGetter
                        ? RequestSender.TokenGetter()
                        : '',
                },
                data: data,
                timeout: timeout,
            })
                .then(res => {
                    if (res.code === 200) resolve(res.data);
                    else reject(res);
                })
                .catch(error => {
                    if (RequestSender.ErrorHandler) {
                        RequestSender.ErrorHandler(error);
                        Loader.CloseAll();
                    }
                });
        });
    }


    static uploadMulFile(
        url,
        query,
        form,
        files,
        timeout = 30000,
        onUploadProgress = null
    ) {
        let formData = new FormData();
        for (let key in form) {
            if (Object.prototype.hasOwnProperty.call(form, key))
                formData.append(key, form[key]);
        }
        files.forEach((file) => {
            if(file.content!='') {
                formData.append(file.name, file.content);
            }
        });
        return RequestSender._getRequestWithUnCatch({
            method: 'post',
            url:
                RequestSender._getUrl(url) +
                RequestSender.withQueryString(query),
            baseURL: '',
            headers: {
                AccessToken: RequestSender.TokenGetter
                    ? RequestSender.TokenGetter()
                    : '',
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                let complete =
                    (((progressEvent.loaded / progressEvent.total) * 100) | 0) +
                    '%';
                if (onUploadProgress != null) {
                    onUploadProgress(complete);
                }
            },
            data: formData,
            timeout: timeout,
        });
    }



    static uploadFile(
        url,
        query,
        form,
        file,
        timeout = 30000,
        onUploadProgress = null
    ) {
        let formData = new FormData();
        for (let key in form) {
            if (Object.prototype.hasOwnProperty.call(form, key))
                formData.append(key, form[key]);
        }
        formData.append(file.name, file.content);
        return RequestSender._getRequestWithUnCatch({
            method: 'post',
            url:
                RequestSender._getUrl(url) +
                RequestSender.withQueryString(query),
            baseURL: '',
            headers: {
                AccessToken: RequestSender.TokenGetter
                    ? RequestSender.TokenGetter()
                    : '',
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                let complete =
                    (((progressEvent.loaded / progressEvent.total) * 100) | 0) +
                    '%';
                if (onUploadProgress != null) {
                    onUploadProgress(complete);
                }
            },
            data: formData,
            timeout: timeout,
        });
    }

    static getUrl(uri, params) {
        return (
            RequestSender._getUrl(uri) + RequestSender.withQueryString(params)
        );
    }

    static _getUrl(uri) {
        return config.Host + uri;
    }

    static _getRequest(config) {
        return new Promise((resolve, reject) => {
            axios(config)
                .then(res => {
                    if (res.data.code === 200) {
                        resolve(res.data);
                    } else reject(res.data);
                })
                .catch(error => {
                    reject(error);
                });
        }).catch(error => {
            if (RequestSender.ErrorHandler) {
                RequestSender.ErrorHandler(error);
            }
        });
    }

    static _getRequestWithUnCatch(config) {
        return new Promise((resolve, reject) => {
            axios(config)
                .then(res => {
                    if (res.data.code === 200) {
                        resolve(res.data);
                    } else reject(res.data);
                })
                .catch(error => {
                    reject(error);
                });
        }).catch(error => {
            if (error != null && error.toString().indexOf('timeout of') != -1) {
                error.message = '网络请求失败，请重试！';
            }
            if (RequestSender.ErrorHandler) {
                RequestSender.ErrorHandler(error);
            }
            return Promise.reject(error);
        });
    }

    static withQueryString(params) {
        if (params) return '?' + this.expand(params);
        else return '';
    }
    static expand(params) {
        let paramsStringArray = [];
        for (const key of Object.keys(params)) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                paramsStringArray.push([key, params[key].toString()].join('='));
            }
        }
        return paramsStringArray.join('&');
    }
    static expandAs(params) {
        let paramsStringArray = [];
        for (const key of Object.keys(params)) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                paramsStringArray.push([key, params[key].toString()].join('='));
            }
        }
        return paramsStringArray.join('&');
    }
}

export default RequestSender;
