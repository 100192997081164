import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import StartUp from "./infrastructures/startup";
import { Tree, Icon, Table, TableColumn, Pagination } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
StartUp.Initialize();
Vue.use(Tree);
Vue.use(Icon);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);

new Vue({
  router,
  vuetify,
  Tree,
  render: h => h(App)
}).$mount('#app');
