const keys = {
    ACCESS_TOKEN: 'AccessToken',
    EXAM_INFO: 'ExamInfo',
    REGISTRY_INFO: 'RegistryInfo',
    REPLAY_INFO: 'Replies',
    REPLAY_VERSION: 'Version',
    USER_ROLE: 'UserRole',
    LOGIN_ROUTE: 'LoginRoute',

    AUDIO_INFO: 'AUDIO_INFO',
};
export default keys;
