export default class Navigation {
    _router;

    constructor($router) {
        this._router = $router;
    }

    redirect(name, query) {
        this._router.push({
            name: name,
            query: query,
        });
    }

    replace(name, query) {
        this._router.replace({
            name: name,
            query: query,
        });
    }

    resolve(name, query) {
        return this._router.resolve({
            name: name,
            query: query,
        });
    }

    resolveUrl(name, query) {
        return `${window.location.origin}${this.resolve(name, query).href}`;
    }
}
