import sender from '../request';
export default class ExaminationReportRepository {

    GetExaminationRoomReport(sitecode, pageindex, pagesize, roomType) {
        return sender.getWithUnCatch("/ExaminationReport/GetExaminationRoomReport?sitecode=" + sitecode + "&pageindex=" + pageindex + "&pagesize=" + pagesize + "&roomType=" + roomType)
    }
    GetExaminationSiteReport(areacode, roomType) {
        return sender.getWithUnCatch("/ExaminationReport/GetExaminationSiteReport?areacode=" + areacode + "&roomType=" + roomType)
    }
    GetExaminationAreaReport(roomType) {
        return sender.getWithUnCatch("/ExaminationReport/GetExaminationAreaReport?roomType=" + roomType)
    }

    GetExaminationReport(roomType) {
        return sender.getWithUnCatch("/ExaminationReport/GetExaminationReport?roomType=" + roomType)
    }
}
