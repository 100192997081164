var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"my-1",attrs:{"no-gutters":""}},[_c('v-col',[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(this.context.name))])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.back}},[_vm._v(" 返回 ")])],1)],1),_c('v-divider'),_c('div',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"headers":[
                {
                    text: '设备编号',
                    value: 'equipmentNo',
                    width: '100px',
                },
                {
                    text: '监控状态',
                    value: 'monitorStatus',
                    width: '100px',
                },
                {
                    text: '在线状态',
                    value: 'onlineStatus',
                    width: '100px',
                },
                {
                    text: '设备状态',
                    value: 'equipmentStatus',
                    width: '100px',
                },
                {
                    text: '最后在线时间',
                    value: 'lastOnlineTime',
                    width: '100px',
                } ],"items":_vm.equipments,"no-data-text":"暂无数据","items-per-page":10}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }