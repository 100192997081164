<template>
    <div>
        <video v-if="item.videoUrl" v-show="isShowVideo" muted @ended="endVideo"
               ref="vidoeplayer" width="100%" height="800" controls="controls">
            <source
                :src="item.videoUrl"
                type="video/mp4">
        </video>

    </div>
</template>

<script>
    import { Vue, Component, Prop } from 'vue-property-decorator';

    export default @Component
    class VideoPlayerComponent extends Vue {
        @Prop() item;
        @Prop() index;
        isShowVideo = false;

        mounted() {
            //默认进来播放第一段视频
            if (this.index == 0) {
                this.$refs.vidoeplayer.play();
                this.isShowVideo = true;
            } else {
                this.isShowVideo = false;
            }
        }

        endVideo() {
            console.log(this.index);
            this.$emit('UploadIsOver', this.index); //通知父组件
        }

        audioPlay() {
            this.$refs.vidoeplayer.play();
        }
    }

</script>

