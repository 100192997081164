<template>
    <div class="pa-4">
        <v-card>
            <div class="d-flex">
                <v-card-title class="title font-weight-bold">
                    系统设置
                </v-card-title>
            </div>
            <v-divider></v-divider>
            <div>
                <div class="d-flex mt-4 mx-6">
                    <label class="mt-2">总控服务平台Url：</label>
                    <v-text-field
                        v-model="sysInfo.servicePlatformHost"
                        placeholder="请输入总控服务平台Url"
                        dense
                        outlined
                    ></v-text-field>
                </div>
                <div class="text-center">
                    <v-btn color="primary" class="my-2" @click="saveSetNo">
                        保存
                    </v-btn>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Loading } from '@/infrastructures/decorators/Loading';

export default
@Component({})
class ExaminationRoom extends Vue {
    static name = window.$views.ExamCsp.SystemConfig.Index;
    @Inject()
    repository;
    @Inject()
    notifier;
    @Inject()
    enums;

    sysInfo = {};

    mounted() {
        this.getDetails();
    }

    getDetails() {
        this.repository.ExaminationRoom.GetSystemConfig().then((res) => {
            this.sysInfo = res.data;
        });
    }

    @Loading('设置中')
    async saveSetNo() {
        await this.repository.ExaminationRoom.UpdateSystemConfig(this.sysInfo)
            .then(() => {
                this.notifier.success('设置成功！');
            })
            .catch((res) => {
                this.notifier.error(res.message);
            });
    }
}
</script>