/**
 * 定时器
 */
export default class Timer {
    /**
     * 内部定时器唯一标识
     * @type {number}
     * @private
     */
    _identifier = null;

    /**
     * 定时间隔,秒
     * @type {number}
     * @private
     */
    _second = 1000;

    /**
     * 定时执行的委托方法
     * @type {null}
     * @private
     */
    _delegate = null;

    constructor(delegate, second) {
        this._delegate = delegate;
        this._second = second;
    }

    /**
     * 创建一个秒级定时器
     * @param delegate
     * @param second
     * @returns {Timer}
     * @constructor
     */
    static CreateFromSecond(delegate, second) {
        return new Timer(delegate, second);
    }

    /**
     * 创建一个分钟级别定时器
     * @param delegate
     * @param minute
     * @returns {Timer}
     * @constructor
     */
    static CreateFromMinute(delegate, minute) {
        return new Timer(delegate, minute * 60);
    }

    SetInterval(val) {
        this._second = val;
        return this;
    }

    OnTick(delegate) {
        this._delegate = delegate;
        return this;
    }

    Start(randomSeconds = 0) {
        if (this._identifier) return;
        let duration = (this._second + randomSeconds) * 1000;
        this._identifier = setInterval(this._delegate, duration);
    }

    Stop() {
        if (!this._identifier) return;
        clearInterval(this._identifier);
        this._identifier = null;
    }

    get Active() {
        return this._identifier != null;
    }
}
