import DistrictRecruitmentStatus from '@/domain/enums/DistrictRecruitmentStatus';
import ApprovalStatus from '@/domain/enums/ApprovalStatus';
import UserType from '@/domain/enums/UserType';
import TreeType from '@/domain/enums/TreeType';
export default {
    districtRecruitmentStatus: new DistrictRecruitmentStatus(),
    approvalStatus: new ApprovalStatus(),
    UserType: new UserType(),
    TreeType: new TreeType(),
};
