import trtc from 'trtc-js-sdk';
import config from '@/config';

export default class TrtcMonitor {
    _instance = null;
    _joined = false;

    constructor(options) {
        //trtc.Logger.disableUploadLog();
        this._instance = trtc.createClient(options);
    }

    /**
     * 创建监控端
     * @param id
     * @param sig
     * @returns {TrtcMonitor}
     * @TrtcMonitor
     */
    static CreateMonitor(id, sig) {
        return new TrtcMonitor({
            sdkAppId: config.Trtc.SdkAppId,
            userId: id,
            userSig: sig,
            mode: 'videoCall',
        });
    }

    /* 操作API Start */

    /**
     * 进入考场
     * @param hall
     * @returns {Promise<void>}
     * @constructor
     */
    async Join(hall) {
        try {
            console.log(this._instance);
            await this._instance.join({
                roomId: hall,
            });
            this._joined = true;
        } catch (e) {
            throw Error(`进入房间失败: ${e}`);
        }
    }

    async PushFlowJoin(hall) {
        try {
            await this._instance.join({
                roomId: hall,
            });
            this._joined = true;
        } catch (e) {
            throw Error(`进入房间失败: ${e}`);
        }
    }

    /**
     * 主动离开考场
     * @returns {Promise<void>}
     * @constructor
     */
    async Leave() {
        if (this._joined) {
            await this._instance.leave();
        }
    }

    /**
     * 查看指定用户的流(订阅)
     * @param stream
     * @param options
     * @returns {Promise<void>}
     * @constructor
     */
    async Watch(stream, options) {
        if (this._joined) {
            this._instance.subscribe(stream, options);
        }
    }

    /**
     * 忽略指定用户的流(取消订阅)
     * @param stream
     * @returns {Promise<void>}
     * @constructor
     */
    async Overlook(stream) {
        if (this._joined) {
            this._instance.unsubscribe(stream);
            this._instance.off('*');
        }
    }
    /* 操作API End */

    /* 事件Hook列表 Start */

    /**
     * 当有用户进入房间前
     * @param delegate
     * @constructor
     */
    WhenClientJoin(delegate) {
        this._instance.on('stream-added', delegate);
    }

    /**
     * 当用户进入房间后
     * @param delegate
     * @constructor
     */
    AfterClientJoin(delegate) {
        this._instance.on('stream-subscribed', delegate);
    }

    /**
     * 当禁用视频时
     * @param delegate
     * @constructor
     */
    WhenMuteVideo(delegate) {
        this._instance.on('mute-video', delegate);
    }

    /**
     * 当取消禁用视频时
     * @param delegate
     * @constructor
     */
    WhenUnMuteVideo(delegate) {
        this._instance.on('unmute-video', delegate);
    }

    /**
     * 错误处理
     * @param delegate
     * @constructor
     */
    ReceivedError(delegate) {
        this._instance.on('error', delegate);
    }
    /* 事件Hook列表 End */
}
