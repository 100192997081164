<template>
    <div class="organization">
        <div class="d-flex mb-4">
            <v-col cols="6" class="pa-0">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    hide-details
                    placeholder="请输入账号"
                    solo
                    dense
                ></v-text-field>
            </v-col>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="ml-2"
                        outlined
                        color="primary"
                        v-on="on"
                        @click="showAdd"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>新增</span>
            </v-tooltip>

            <v-btn class="ml-2" color="error" outlined @click="cancel">
                <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
        </div>

        <v-data-table
            :headers="[
                { text: '账号', value: 'account' },
                { text: '姓名', value: 'userName' },
                { text: '操作', value: 'options' },
            ]"
            :items="users"
            :search="search"
            class="mt-2"
            no-data-text="暂无数据"
        >
            <template v-slot:item.options="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="ml-2"
                            fab
                            dark
                            x-small
                            outlined
                            color="primary"
                            v-on="on"
                            @click="showUpdate(item)"
                        >
                            <v-icon>mdi-square-edit-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>编辑</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="ml-2"
                            fab
                            dark
                            x-small
                            outlined
                            color="primary"
                            v-on="on"
                            @click="showUpdatePassWord(item)"
                        >
                            <v-icon>mdi-square-edit-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>修改密码</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog :value="dialog" width="400" persistent>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-card-title
                        class="headline blue darken-1 white--text"
                        dark
                        primary-title
                    >
                        新增账号
                    </v-card-title>
                    <v-card-text class="mt-6">
                        <div class="d-flex">
                            <label class="label">账号：</label>
                            <v-text-field
                                placeholder="请输入账号"
                                :rules="[rules.requireByTip('请输入账号')]"
                                solo
                                dense
                                v-model="curUser.account"
                                required
                            ></v-text-field>
                        </div>

                        <div class="d-flex">
                            <label class="label">姓名：</label>
                            <v-text-field
                                placeholder="请输入姓名"
                                :rules="[rules.requireByTip('请输入账号')]"
                                solo
                                dense
                                v-model="curUser.userName"
                                required
                            ></v-text-field>
                        </div>

                        <div class="d-flex">
                            <label class="label">密码：</label>
                            <v-text-field
                                placeholder="请输入密码"
                                solo
                                :rules="[rules.requireByTip('请输入密码')]"
                                type="password"
                                dense
                                v-model="curUser.passWord"
                                required
                            ></v-text-field>
                        </div>

                        <div class="d-flex">
                            <label class="label">确认密码：</label>
                            <v-text-field
                                placeholder="请输入确认密码"
                                :rules="[rules.requireByTip('请输入确认密码')]"
                                solo
                                dense
                                type="password"
                                v-model="curUser.confirmPassword"
                                required
                            ></v-text-field>
                        </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="primary" @click="add">确定</v-btn>
                        <v-btn color="primary" outlined @click="dialog = false">
                            取消
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-dialog :value="dialogUpdate" width="400" persistent>
            <v-form ref="updateform" v-model="valid" lazy-validation>
                <v-card>
                    <v-card-title
                        class="headline blue darken-1 white--text"
                        dark
                        primary-title
                    >
                        修改账号信息
                    </v-card-title>

                    <v-card-text class="mt-6">
                        <div class="d-flex">
                            <label class="label">账号：</label>
                            <label style="padding-top:10px;">{{ curUser.account }}</label>
                        </div>

                        <div class="d-flex">
                            <label class="label">姓名：</label>
                            <v-text-field
                                placeholder="请输入姓名"
                                :rules="[rules.requireByTip('请输入姓名')]"
                                solo
                                dense
                                v-model="curUser.userName"
                                required
                            ></v-text-field>
                        </div>

                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="primary" @click="update">确定</v-btn>
                        <v-btn
                            color="primary"
                            outlined
                            @click="dialogUpdate = false"
                        >
                            取消
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-dialog :value="dialogUpdatePwd" width="400" persistent>
            <v-form ref="updatePwdform" v-model="valid" lazy-validation>
                <v-card>
                    <v-card-title
                        class="headline blue darken-1 white--text"
                        dark
                        primary-title
                    >
                        修改密码
                    </v-card-title>

                    <v-card-text class="mt-6">
                        <div class="d-flex">
                            <label class="label">账号：</label>
                            <label style="padding-top:10px;">{{ curUser.account }}</label>
                        </div>

                        <div class="d-flex">
                            <label class="label">新密码&nbsp;&nbsp; ：</label>
                            <v-text-field
                                placeholder="请输入新密码"
                                solo
                                type="password"
                                dense
                                v-model="curUser.newPassWord"
                                required
                            ></v-text-field>
                        </div>

                        <div class="d-flex">
                            <label class="label">确认密码：</label>
                            <v-text-field
                                placeholder="请输入确认密码"
                                solo
                                dense
                                type="password"
                                v-model="curUser.confirmPassword"
                                required
                            ></v-text-field>
                        </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="primary" @click="updatePassWord">
                            确定
                        </v-btn>
                        <v-btn
                            color="primary"
                            outlined
                            @click="dialogUpdatePwd = false"
                        >
                            取消
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: window.$views.Organization.Detail,
    meta: {
        navigable: false,
        title: '机构账号管理',
    },
    inject: ['repository', 'notifier', 'rules'],
    data() {
        return {
            valid: true,
            users: [],
            curUser: {
                account: '',
                passWord: '',
                newPassword: '',
                userName: '',
                confirmPassword:'',
                organizationId:'',
            },
            dialog: false,
            search: '',
            dialogUpdate: false,
            dialogUpdatePwd: false,
        };
    },
    mounted() {
         this.GetUsers();
    },
    methods: {
        async updatePassWord() {
            if (this.$refs.updatePwdform.validate() == false) return;
            if (
                this.curUser.newPassWord != '' &&
                this.curUser.newPassWord != this.curUser.confirmPassword
            ) {
                return this.notifier.error('确认密码不一致');
            }
            this.curUser.organizationId = this.context.organization;
            await this.repository.User.SetPassword(this.curUser)
                .then(() => {
                    this.notifier.success('修改成功');
                    this.GetUsers();
                    this.dialogUpdatePwd = false;
                })
                .catch((res) => {
                    this.notifier.error(res.message);
                });
        },
        showUpdatePassWord(item) {
            this.curUser = item;
            this.curUser.passWord = '';
            this.curUser.confirmPassword = '';
            this.dialogUpdatePwd = true;
        },
        resetPassWord(item) {
            this.$confirm('提示', '是否重置该用户密码？').then(() => {
                this.repository.User.ResetPassWord({
                    channelId: item.channelId,
                })
                    .then((res) => {
                        this.notifier.success(res.message);
                    })
                    .catch((res) => {
                        this.notifier.error(res.message);
                    });
            });
        },
        cancel() {
            this.$router.back(-1);
        },
        async showAdd() {
            this.dialog = true;
            this.curUser = {
                account: '',
                passWord: '',
                confirmPassword: '',
                channelId: '',
            };
        },
        async showUpdate(item) {
            this.curUser = item;
            this.curUser.passWord = '';
            this.curUser.confirmPassword = '';
            this.dialogUpdate = true;
        },
        async update() {
            if (this.$refs.updateform.validate() == false) return;
            this.curUser.organizationId = this.context.organization;
            await this.repository.User.AddOrUpdate(this.curUser)
                .then(() => {
                    this.notifier.success('修改成功');
                    this.GetUsers();
                    this.dialogUpdate = false;
                })
                .catch((res) => {
                    this.notifier.error(res.message);
                });
        },
        async GetUsers() {
            var res = await this.repository.User.GetUsers(
                {organizationId:this.context.organization}
            );
            this.users = res.data;
        },
        async add() {
            if (this.$refs.form.validate() == false) return;
            if (this.curUser.passWord != this.curUser.confirmPassword) {
                return this.notifier.error('确认密码不一致');
            }
            this.curUser.organizationId = this.context.organization;
            await this.repository.User.AddOrUpdate(this.curUser)
                .then(() => {
                    this.notifier.success('新增成功');
                    this.GetUsers();
                })
                .catch((res) => {
                    this.notifier.error(res.message);
                    return;
                });
            this.dialog = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.organization {
    width: 80vw;
    margin: 20px auto;
}
.label {
    padding-top: 10px;
    padding-right: 10px;
}
.wrapper-page {
    float: right;
    margin-top: 20px;
}
.d-flex {
    height: 100% ;
}
</style>
