var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","width":"8","size":"120"}},[_vm._v(" 数据加载中 ")])],1),_c('v-row',{staticClass:"my-1 mr-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(this.context.siteName))])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.back}},[_vm._v("返回")])],1)],1),_c('v-divider'),_c('div',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"headers":[
                {
                    text: '考场代码',
                    value: 'centerNo',
                    width: '100px',
                },
                {
                    text: '考场名称',
                    value: 'name',
                    width: '100px',
                },
                {
                    text: '考场上报进度',
                    value: 'readedCount',
                    width: '100px',
                },
                {
                    text: '操作',
                    value: 'options',
                    width: '100px',
                } ],"options":_vm.options,"items":_vm.roomReports,"no-data-text":"暂无数据","items-per-page":10,"server-items-length":_vm.totalDesserts},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.readedCount",fn:function(ref){
            var item = ref.item;
return [_c('replyProcessLinear',{attrs:{"value":_vm.Percentage(item.readedCount, item.totalCount)}})]}},{key:"item.options",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.ViewDetail(item)}}},[_vm._v(" 详情 ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.equipmentDialog),callback:function ($$v) {_vm.equipmentDialog=$$v},expression:"equipmentDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("设备详情")]),_c('v-data-table',{attrs:{"headers":[
                    {
                        text: '设备编号',
                        value: 'equipmentNo',
                        width: '100px',
                    },
                    {
                        text: '设备状态',
                        value: 'equipMentStatusName',
                        width: '100px',
                    } ],"items":_vm.equipmentReports,"no-data-text":"暂无数据","items-per-page":20,"hide-default-footer":""}}),_c('div',{staticClass:"text-center pa-2"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.equipmentDialog = false}}},[_vm._v(" 关闭 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }