<template>
    <v-card>
        <v-card-title class="headline primary white--text">
            修改密码
        </v-card-title>
        <v-row no-gutters class="mt-4 mx-6">
            <v-col class="mt-2 text-right" cols="3"><label class="mt-2">原密码：</label></v-col>
            <v-col>
                <v-text-field
                        v-model="setpassword.Password"
                    type="password"
                    placeholder="请输入原密码"
                    dense
                    required
                    outlined
                />
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-4 mx-6">
            <v-col class="mt-2 text-right" cols="3"><label class="mt-2">新密码：</label></v-col>
            <v-col>
                <v-text-field
                        v-model="setpassword.NewPassword"
                    type="password"
                    placeholder="请输入新密码"
                    dense
                    required
                    outlined
                />
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-4 mx-6">
            <v-col class="mt-2 text-right" cols="3"><label class="mt-2">确认新密码：</label></v-col>
            <v-col>
                <v-text-field
                        v-model="setpassword.ReNewPassword"
                    type="password"
                    placeholder="请再次输入新密码"
                    dense
                    required
                    outlined
                />
            </v-col>
        </v-row>
        <v-divider></v-divider>

        <div class="text-center py-2">
            <v-btn e2e-save-city color="primary" @click="save">
                保存
            </v-btn>
            <v-btn
                color="primary"
                class="ml-2"
                outlined
                @click="closePassword"
            >
                取消
            </v-btn>
        </div>
    </v-card>
</template>

<script>
    import {Vue, Component, Inject,} from 'vue-property-decorator';
    import Storage from "../../domain/storage";
    import keys from "../../domain/keys";

    export default @Component({})
    class password extends Vue {
        @Inject()
        repository;
        @Inject()
        notifier;
        @Inject()
        loader;
        @Inject()
        navigation;
        setpassword = {Id: '', Password: '', NewPassword: '', ReNewPassword: '', type: 0}

        created() {
            if (Storage.Get(keys.REGISTRY_INFO) != undefined && Storage.Get(keys.REGISTRY_INFO) != null) {
                this.setpassword.id = JSON.parse(Storage.Get(keys.REGISTRY_INFO)).id;
                this.setpassword.type = JSON.parse(Storage.Get(keys.REGISTRY_INFO)).type;
            }
        }

        save() {
            if (this.setpassword.Password == '') {
                this.notifier.warn(`请输入原密码`);
                return;
            }
            if (this.setpassword.NewPassword == '') {
                this.notifier.warn(`请输入新密码`);
                return;
            }
            if (this.setpassword.ReNewPassword == '') {
                this.notifier.warn(`请确认新密码`);
                return;
            }
            if (this.setpassword.ReNewPassword != this.setpassword.NewPassword) {
                this.notifier.warn(`确认新密码和新密码不一致,请重新输入`);
                return;
            }
            this.repository.ExaminationDistrict.SetPassword(
                this.setpassword,
            ).then(() => {
                this.notifier.success('密码重置成功');
                this.closePassword();
            });

        }

        closePassword() {
            this.setpassword.Password = ''
            this.setpassword.NewPassword = ''
            this.setpassword.ReNewPassword='';
            this.$emit('passwordBack');
        }
    }
</script>
