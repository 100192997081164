var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","width":"8","size":"120"}},[_vm._v(" 数据加载中 ")])],1),_c('v-row',{staticClass:"my-1 mr-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(this.context.districtName))])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.back()}}},[_vm._v("返回")])],1)],1),_c('v-divider'),_c('div',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"headers":[
                {
                    text: '考点代码',
                    value: 'siteCode',
                    width: '100px',
                },
                {
                    text: '考点名称',
                    value: 'name',
                    width: '100px',
                },
                {
                    text: '考场数量',
                    value: 'roomCount',
                    width: '100px',
                },
                {
                    text: '考点上报进度',
                    value: 'readedCount',
                    width: '100px',
                },
                {
                    text: '操作',
                    value: 'options',
                    width: '100px',
                } ],"items":_vm.siteReports,"no-data-text":"暂无数据","items-per-page":10},scopedSlots:_vm._u([{key:"item.readedCount",fn:function(ref){
            var item = ref.item;
return [_c('replyProcessLinear',{attrs:{"value":_vm.Percentage(item.readedCount, item.totalCount)}})]}},{key:"item.options",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.ViewSiteDetail(item)}}},[_vm._v(" 详情 ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }