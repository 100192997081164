import EnumItem from './EnumItem.js';

class Enum {
    constructor() {
        if (new.target === Enum) {
            throw new Error('枚举基类不可直接实例化');
        }
    }
    parse(value) {
        const keys = Reflect.ownKeys(this);
        for (let property_index in keys) {
            let property_name = keys[property_index];
            let property = this[property_name];
            if (!(property instanceof EnumItem)) continue;
            if (property.value === value) return property;
        }
        throw new Error('找不到值为' + value + '的枚举值');
    }
    toList() {
        const keys = Reflect.ownKeys(this);
        let _list = [];

        for (let property_index in keys) {
            let property_name = keys[property_index];
            let property = this[property_name];
            if (property instanceof EnumItem) {
                var _listObj = {
                    label: property.name,
                    value: property.value,
                };
                _list.push(_listObj);
            }
        }

        return _list;
    }
}

export default Enum;
