<template>
    <div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate width="8" size="120">
                数据加载中
            </v-progress-circular>
        </v-overlay>
        <v-row no-gutters class="my-1 mr-2">
            <v-col>
                <h3 class="text-center">{{ this.context.siteName }}</h3>
            </v-col>
            <v-col cols="auto">
                <v-btn outlined color="primary" @click="back">返回</v-btn>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="pa-4">
            <v-data-table
                :headers="[
                    {
                        text: '考场代码',
                        value: 'centerNo',
                        width: '100px',
                    },
                    {
                        text: '考场名称',
                        value: 'name',
                        width: '100px',
                    },
                    {
                        text: '考场监控进度',
                        value: 'mointoringCount',
                        width: '100px',
                    },
                    {
                        text: '操作',
                        value: 'options',
                        width: '100px',
                    },
                ]"
                :options.sync="options"
                :items="roomReports"
                no-data-text="暂无数据"
                :items-per-page="10"
                :server-items-length="totalDesserts"
            >
                <template v-slot:item.mointoringCount="{ item }">
                    <replyProcessLinear
                        :value="
                            Percentage(item.mointoringCount, item.totalCount)
                        "
                    ></replyProcessLinear>
                </template>
                <template v-slot:item.options="{ item }">
                    <v-btn
                        @click="ViewDetail(item)"
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                    >
                        详情
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <v-dialog max-width="500" persistent v-model="equipmentDialog">
            <v-card>
                <v-card-title>设备详情</v-card-title>
                <v-data-table
                    :headers="[
                        {
                            text: '设备编号',
                            value: 'equipmentNo',
                            width: '100px',
                        },
                        {
                            text: '设备状态',
                            value: 'monitorStatusName',
                            width: '100px',
                        },
                    ]"
                    :items="equipmentReports"
                    no-data-text="暂无数据"
                    :items-per-page="20"
                    hide-default-footer
                ></v-data-table>
                <div class="text-center pa-2">
                    <v-btn color="primary" @click="equipmentDialog = false">
                        关闭
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { Vue, Watch, Component, Inject } from 'vue-property-decorator';
import replyProcessLinear from '@/components/replyProcessLinear/replyProcessLinear';
export default
@Component({ components: { replyProcessLinear } })
class EquipmentReports extends Vue {
    static name = window.$views.ExamCsp.ExaminationReport.MonitorRoom;
    @Inject()
    repository;
    @Inject()
    notifier;
    @Inject()
    loader;
    roomReports = [];
    equipmentReports = [];
    equipmentDialog = false;
    loading = false;
    options = { page: 1, itemsPerPage: 10 };
    totalDesserts = 0;
    mounted() {
        this.GetExaminationRoomCount();
        this.GetExaminationRoomReport();
    }
    @Watch('options', { deep: true })
    onOptionsChanged() {
        this.GetExaminationRoomCount();
        this.GetExaminationRoomReport();
    }

    GetExaminationRoomReport() {
        this.loading = true;
        let roomType = localStorage.getItem('roomType');
        this.repository.ExaminationReport.GetExaminationRoomReport(
            this.context.siteCode,
            this.options.page,
            this.options.itemsPerPage,
            roomType
        ).then((res) => {
            this.roomReports = res.data;
            this.loading = false;
        });
    }
    async GetExaminationRoomCount() {
        let roomType = localStorage.getItem('roomType');
        this.repository.ExaminationRoom.GetExaminationRooms({
            ExaminationSiteCode: this.context.siteCode,
            RoomType: roomType,
        }).then((res) => {
            this.totalDesserts = res.data.length;
        });
    }

    async ViewDetail(item) {
        this.equipmentReports = [];
        this.equipmentDialog = true;
        await this.repository.ExaminationRoom.GetEquipments(item.centerNo).then(
            (res) => {
                this.equipmentReports = res.data;
            }
        );
    }

    back() {
        this.$router.go(-1);
    }

    Percentage(num, total) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Math.floor((num / total) * 10000) / 100.0;
    }
}
</script>

<style scoped>
</style>
