<!--
 * @Descripttion : 
 * @Author       : pishengjun
 * @Date         : 2021-09-24 09:38:45
 * @LastEditors  : pishengjun
 * @LastEditTime : 2021-09-24 11:22:31
 * @FilePath     : \ExamMonitorSystem.ServicePlatform.UI\src\views\Monitor\ExaminationSiteMonitor\Index.vue
-->
<template>
    <div class="full">
        <monitor-view
            :title-handler="GetExaminationSites"
            :page-handler="GetOnlineEquipmentCount"
            :page-data-handler="GetOnlineEquipments"
        >
            <template #options>
                <v-btn @click="back" outlined dark>返回</v-btn>
            </template>
        </monitor-view>
    </div>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import MonitorView from '@/components/MonitorView/Extend';
import Storage from '@/domain/storage';
import keys from '@/domain/keys';

export default
@Component({
    components: { MonitorView },
})
class ExaminationRoomMonitor extends Vue {
    static name = window.$views.Monitor.ExaminationSiteMonitor.Index;
    @Inject()
    notifier;

    @Inject()
    navigation;

    @Inject()
    repository;
    siteList = {};
    get code() {
        return this.context.code;
    }
    created() {
        if (
            Storage.Get(keys.REGISTRY_INFO) != undefined &&
            Storage.Get(keys.REGISTRY_INFO) != null
        ) {
            this.siteList.type = JSON.parse(
                Storage.Get(keys.REGISTRY_INFO)
            ).type;
        }
    }
    back() {
        if (this.siteList.type == 1) {
            this.navigation.redirect(this.$views.ExamCsp.ExaminationSite.List, {
                code: this.code,
                areacode: this.context.areacode,
                type: this.context.type,
            });
        } else {
            // history.go(-1)
            this.navigation.redirect(
                this.$views.ExaminationAdmin.ExaminationSite.List,
                {
                    code: this.$route.query.parentCode,
                    // areacode: this.context.areacode,
                }
            );
        }
    }

    // monitor 数据封装
    async GetOnlineEquipments(page) {
        let roomType = localStorage.getItem('roomType');
        let response =
            await this.repository.ExaminationRoom.GetOnlineMonitorEquipments({
                groupNo: this.code,
                pageSize: page.size,
                page: page.cur,
                roomType: roomType,
            });

        return response.data;
    }

    async GetOnlineEquipmentCount() {
        let roomType = localStorage.getItem('roomType');
        let response =
            await this.repository.ExaminationRoom.GetOnlineEquipmentCount({
                groupNo: this.code,
                roomType: roomType,
            });
        return response.data;
    }

    async GetExaminationSites() {
        let roomType = localStorage.getItem('roomType');
        let response =
            await this.repository.ExaminationSite.GetExaminationSites({
                code: this.code,
                roomType: roomType,
            });

        return response.data;
    }
}
</script>
