import Vue from 'vue';
import vuetify from '../../plugins/vuetify';
import MessageComponent from './main';

let MessageConstructor = Vue.extend(MessageComponent);

export default class Notification {
    static _instances = [];
    static _zIndex = 2000;
    static _seed = 0;
    static _messageBaseOffset = 20;
    static _messageSpace = 15;

    static notify(options) {
        let instance = Notification._create(options);
        Notification._instances.push(instance);
    }

    static success(text) {
        Notification.notify({ message: text, color: 'success' });
    }

    static error(text) {
        Notification.notify({ message: text, color: 'error' });
    }

    static primary(text) {
        Notification.notify({ message: text, color: 'primary' });
    }

    static warn(text) {
        Notification.notify({ message: text, color: 'warning' });
    }

    static _generateId() {
        Notification._seed += 1;
        return `custom_message_alter_${Notification._seed}`;
    }

    static _close(id) {
        let removeElement = Notification._remove(id);
        Notification._reorderInstances(removeElement);
    }

    static _create(options) {
        let id = Notification._generateId();
        options.onClose = function() {
            Notification._close(id);
        };

        let instance = new MessageConstructor({
            vuetify,
            data: options,
        });
        instance.id = id;
        instance.$mount();
        document.getElementById('app').appendChild(instance.$el);

        Notification._pushInstance(instance);

        return instance;
    }

    static _remove(id) {
        let len = Notification._instances.length;
        for (let index = 0; index < len; index++) {
            let instance = Notification._instances[index];
            if (instance.id === id) {
                Notification._instances.splice(index, 1);
                return {
                    index: index,
                    offsetHeight: instance.$el.offsetHeight,
                };
            }
        }
        return null;
    }

    static _reorderInstances(removeInstance) {
        let len = Notification._instances.length;

        for (let index = removeInstance.index; index < len; index++) {
            let popInstance = Notification._instances[index];
            let offsetHeight =
                parseInt(popInstance.$el.style['top'], 10) -
                removeInstance.offsetHeight -
                Notification._messageSpace;
            popInstance.$el.style['top'] = `${offsetHeight}px`;
        }
    }

    static _pushInstance(instance) {
        let verticalOffset = Notification._messageBaseOffset;
        Notification._instances.forEach(
            item =>
                (verticalOffset +=
                    item.$el.offsetHeight + Notification._messageSpace)
        );
        instance.verticalOffset = verticalOffset;
        instance.$el.style.zIndex = Notification._zIndex;
    }
}
