<template>
    <div class="relative">
        <v-btn
            class="ma-4"
            color="primary"
            @click="AddClick"
            v-show="user.JurisdictionCheck"
        >
            新增考区
        </v-btn>

        <v-btn class="ma-4" color="primary" @click="allLookMonitor">
            查看监控
        </v-btn>

        <a
            v-if="isPublishUrlShow"
            class="blue--text"
            :href="publishUrl"
            target="_blank"
            style="position: absolute; right: 15px; top: 63px;">
            <v-btn class="primary-btn" color="success">
                <v-icon size="20">mdi-link-variant</v-icon>
                <span>考区管理员登录地址</span>
            </v-btn>
        </a>
        <div class="px-4 pt-n4">
            <v-data-table
                :headers="[
                    {
                        text: '考区代码',
                        value: 'areaCode',
                        width: '100px',
                    },
                    {
                        text: '考区名称',
                        value: 'areaName',
                        width: '100px',
                    },
                    {
                        text: '操作',
                        value: 'options',
                        width: '100px',
                    },
                ]"
                :items-per-page="10"
                :items="examinationdistricts"
                no-data-text="暂无数据"
            >
                <template v-slot:item.options="{ item }">
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        @click="lookMonitor(item)"
                    >
                        查看监控
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        :to="
                            $to($views.ExamCsp.ExaminationSite.List, {
                                areacode: item.areaCode,
                                type: 1,
                                areaname: item.areaName,
                            })
                        "
                    >
                        考点管理
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        v-show="user.JurisdictionCheck"
                        class="mr-2"
                        @click="resetAreaPwd(item)"
                    >
                        重置密码
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        v-show="user.JurisdictionCheck"
                        class="mr-2"
                        @click="UpdateClick(item)"
                    >
                        修改
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        v-show="user.JurisdictionCheck"
                        outlined
                        @click="deleteClick(item.id)"
                    >
                        删除
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <v-dialog max-width="600" persistent v-model="accountSettingsDialog">
            <v-card>
                <v-card-title class="headline primary white--text">
                    重置密码
                </v-card-title>
                <v-row no-gutters class="mt-4 mx-6">
                    <v-col class="mt-2 text-right" cols="3">
                        <label class="mt-2">原密码：</label>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="setpassword.Password"
                            type="password"
                            placeholder="请输入原密码"
                            dense
                            required
                            outlined
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-4 mx-6">
                    <v-col class="mt-2 text-right" cols="3">
                        <label class="mt-2">新密码：</label>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="setpassword.NewPassword"
                            type="password"
                            placeholder="请输入新密码"
                            dense
                            required
                            outlined
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-4 mx-6">
                    <v-col class="mt-2 text-right" cols="3">
                        <label class="mt-2">确认新密码：</label>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="setpassword.ReNewPassword"
                            type="password"
                            placeholder="请再次输入新密码"
                            dense
                            required
                            outlined
                        />
                    </v-col>
                </v-row>
                <v-divider></v-divider>

                <div class="text-center py-2">
                    <v-btn e2e-save-city color="primary" @click="saveAccount">
                        保存
                    </v-btn>
                    <v-btn
                        @click="accountSettingsDialog = false"
                        color="primary"
                        class="ml-2"
                        outlined
                    >
                        取消
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog max-width="500" persistent v-model="addDistrictDialog">
            <v-card>
                <v-card-title class="headline primary white--text">
                    {{ this.filedname }}
                </v-card-title>
                <div class="d-flex mt-4 mx-6">
                    <label class="mt-2">考区代码：</label>
                    <v-text-field
                        v-model="addDistrictInfo.AreaCode"
                        placeholder="请输入考区代码"
                        dense
                        required
                        outlined
                    />
                </div>
                <div class="d-flex mt-4 mx-6">
                    <label class="mt-2">考区名称：</label>
                    <v-text-field
                        v-model="addDistrictInfo.AreaName"
                        placeholder="请输入考区名称"
                        dense
                        required
                        outlined
                    />
                </div>
                <v-divider></v-divider>

                <div class="text-center py-2">
                    <v-btn e2e-save-city @click="save()" color="primary">
                        保存
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="ml-2"
                        outlined
                        @click="addDistrictDialog = false"
                    >
                        取消
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: window.$views.ExamCsp.ExaminationDistrict.List,
    inject: ['repository', 'navigation', 'notifier', 'user','enums'],
    data() {
        return {
            publishUrl:'',
            isPublishUrlShow:false,
            filedname: '新增考区',
            areaname: '',
            accountSettingsDialog: false,
            addDistrictDialog: false,
            addDistrictInfo: { Id: '', AreaName: '', AreaCode: '' },
            setpassword: {
                Id: '',
                Password: '',
                NewPassword: '',
                ReNewPassword: '',
            },
            examinationdistricts: [],
        };
    },
    mounted() {
        let loginInfo = this.user.GetInfo;
        if(loginInfo.type == this.enums.UserType.考区管理员.value) this.isPublishUrlShow = false;
        else {
            this.isPublishUrlShow = true;
            this.publishUrl = this.navigation.resolveUrl(
                this.$views.Login,
                {
                    organization: loginInfo.organization,
                }
            );
        }
        this.getExaminationdistricts();
    },
    methods: {
        // 市级：查看监控
        lookMonitor(item) {
            console.log(item);
            this.navigation.redirect(
                this.$views.Monitor.ExaminationCityMonitor.Index,
                {
                    from: 'city',
                    code: item.areaCode,
                    areacode: item.areaCode,
                    areaname: item.areaName,
                    type: 1,
                } // 增加 from:'city'参数（视频监控可以切换当前城市内所有考点视频监控）
            );
        },
        // 省级：查看监控
        allLookMonitor() {
            this.navigation.redirect(
                this.$views.Monitor.ExaminationCityMonitor.Index,
                { from: 'city', areacode: '', type: 1, leftMenu: true } // 增加 from:'city'参数（视频监控可以切换当前城市内所有考点视频监控）
            );
        },
        resetAreaPwd(item) {

            var data={Id:item.id};
            this.repository.ExaminationDistrict.ResetAreaPwd(data).then(
                () => {
                    this.notifier.success('密码重置成功');
                }
            );
            // this.accountSettingsDialog = true;
            // this.areaname = item.areaName;
            // this.setpassword.Id = item.id;
            // this.setpassword.Password = '';
            // this.setpassword.NewPassword = '';
            // this.setpassword.ReNewPassword = '';
            // this.setpassword.Type = this.enums.UserType.考区管理员.value;
        },
        saveAccount() {
            this.SetPassWord();
        },
        getExaminationdistricts() {
            this.repository.ExaminationDistrict.GetExaminationDistrict().then(
                (res) => {
                    this.examinationdistricts = res.data;
                }
            );
        },

        save() {
            if (this.isAdd) {
                this.AddExaminationDistrict();
            } else {
                this.UpdateExaminationDistrict();
            }
        },
        AddClick() {
            this.addDistrictDialog = true;
            this.isAdd = true;
            this.addDistrictInfo = {};
        },
        UpdateClick(item) {
            this.filedname = '修改考区';
            this.settingData(item);
            this.addDistrictDialog = true;
            this.isAdd = false;
        },
        settingData(item) {
            this.addDistrictInfo.AreaCode = item.areaCode;
            this.addDistrictInfo.AreaName = item.areaName;
            this.addDistrictInfo.Password = item.passWord;
            this.addDistrictInfo.id = item.id;
        },
        AddExaminationDistrict() {
            if (this.addDistrictInfo.AreaName == null) {
                this.notifier.warn(`请输入考区名称`);
                return;
            }
            if (this.addDistrictInfo.AreaCode == null) {
                this.notifier.warn(`请输入考区代码`);
                return;
            }
            this.repository.ExaminationDistrict.AddOrUpdate(
                this.addDistrictInfo
            ).then(() => {
                this.addDistrictDialog = false;
                this.notifier.success('保存成功');
                this.getExaminationdistricts();
            });
        },
        SetPassWord() {
            if (this.setpassword.Password == '') {
                this.notifier.warn(`请输入原密码`);
                return;
            }
            if (this.setpassword.NewPassword == '') {
                this.notifier.warn(`请输入新密码`);
                return;
            }
            if (this.setpassword.ReNewPassword == '') {
                this.notifier.warn(`请确认新密码`);
                return;
            }
            if (
                this.setpassword.ReNewPassword != this.setpassword.NewPassword
            ) {
                this.notifier.warn(`确认新密码和新密码不一致,请重新输入`);
                return;
            }
            this.repository.ExaminationDistrict.SetPassword(
                this.setpassword
            ).then(() => {
                this.notifier.success('密码重置成功');
                this.accountSettingsDialog = false;
            });
        },
        UpdateExaminationDistrict() {
            this.repository.ExaminationDistrict.AddOrUpdate(
                this.addDistrictInfo
            ).then(() => {
                this.notifier.success('修改成功');
                this.addDistrictDialog = false;
                this.accountSettingsDialog = false;
                this.getExaminationdistricts();
            });
        },
        deleteClick(id) {
            let roomType = localStorage.getItem('roomType');
            this.$confirm('提示', `确定要删除吗？`).then(() => {
                this.repository.ExaminationDistrict.DelExaminationDistrict(
                    id,
                    roomType
                ).then(() => {
                    this.notifier.success('删除成功');
                    this.getExaminationdistricts();
                });
            });
        },
    },
};
</script>
