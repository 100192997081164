class EnumItem {
    name = '';
    value = 0;
    constructor(name, value) {
        this.name = name;
        this.value = value;
    }
}

export default EnumItem;
