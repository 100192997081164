import sender from '../request';
export default class ExaminationSiteRepository {

    GetExaminationSites(data) {
        return sender.getWithUnCatch("/ExaminationSite/GetExaminationSites", data)
    }
    //导入考场考点数据
    ImportExamSiteAndRoom(data) {
        return sender.postWithUnCatch("/ExaminationSite/ImportExamSiteAndRoom", data)
    }

    AddOrUpdate(data) {
        return sender.postWithUnCatch("/ExaminationSite/AddOrUpdate", data)
    }

    Delete(data) {
        return sender.getWithUnCatch("/ExaminationSite/Delete", data)
    }
    GetExistSiteArea(siteCode) {
        return sender.getWithUnCatch("/ExaminationSite/GetExistSiteArea?siteCode="+siteCode)
    }
    ChangeMonitorShow(data) {
        return sender.postWithUnCatch("/ExaminationSite/ChangeMonitorShow",data)
    }

    ChangeAreaMonitorShow(data) {
        return sender.postWithUnCatch("/ExaminationSite/ChangeAreaMonitorShow",data)
    }
}
