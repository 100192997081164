import XLSX from 'xlsx/xlsx';
import Workbook from './Workbook';
import FileSaver from 'file-saver';

export default class ExcelGenerator {
    _headers = [];
    _widths=[];
    _rows = [];
    _workbook = {};

    constructor() {
        this._workbook = new Workbook();
    }

    static Create() {
        return new ExcelGenerator();
    }

    AddHeader(header) {
        this._headers.push(header);
        return this;
    }

    AddHeaders(headers) {
        this._headers = this._headers.concat(headers);
        return this;
    }

    SetWidths(widths) {
        this._widths = this._widths.concat(widths);
        return this;
    }

    AddRow(row) {
        this._rows.push(row);
        return this;
    }

    AddRows(rows) {
        this._rows = this._rows.concat(rows);
        return this;
    }

    Save(fileName) {
        let data = [];
        data.push(this._headers);
        data = data.concat(this._rows);

        let sheet = XLSX.utils.aoa_to_sheet(data);
        sheet['!cols'] = this._widths;

        this._workbook.AddDefaultSheet(sheet);
        let blobData = this._workbook.AsBlob();

        FileSaver.saveAs(blobData, fileName);
    }
}
