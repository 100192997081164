<template>
    <div class="full newMonitor">
        <monitor-view
            :title="examinationSite.name"
            :items="monitors"
            :width="width"
            :enableFil="enableFil"
            :count="page.count"
            :height="height">
            <template #options>
                <v-switch class="mt-5 mx-2"  v-model="autoMute" dark dense>
                    <template #label>静音</template>
                </v-switch>
                <v-switch class="mt-5 mx-2"  v-model="enableFil" dark dense>
                    <template #label>自动填充</template>
                </v-switch>
                <v-switch :disabled="!canSwitch" class="mt-5 mx-2" v-model="enableCarousel" dark dense>
                    <template #label>自动轮播</template>
                </v-switch>
                <div class="task_time">
                    <input class="time_input"
                        :disabled="Boolean(enableCarousel)"
                        v-model.number="taskTime"
                        @blur="validateTime"
                        οnkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                        type="text"
                        size="small"
                        placeholder=""
                        maxlength="4" />
                    <span class="unit">秒</span>
                </div>
                <v-menu bottom dark>
                    <template #activator="{ on }">
                        <v-btn v-bind="btnProps" v-on="on">切换显示模式</v-btn>
                    </template>
                    <v-list>
                        <v-list-item-group v-model="selectedTypeIndex">
                            <v-list-item
                                v-for="(type, index) in viewType"
                                :key="index">
                                <v-list-item-icon>
                                    <v-icon
                                        color="success"
                                        v-if="selectedTypeIndex === index"> mdi-chevron-right </v-icon>
                                </v-list-item-icon>
                                <v-list-item-title> {{ type.text }} </v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                <v-btn v-bind="btnProps" @click="prev">上一页</v-btn>
                <v-btn v-bind="btnProps" @click="next">下一页</v-btn>
                <slot name="options"></slot>
            </template>
        </monitor-view>
        <div v-if="showLeftMenu" class="selectTree">
            <div class="cont">
                <div class="menuBar">
                    <span v-if="showTree" @click="clickHidenTree">
                        <i class="el-icon-s-fold"></i> 收起 </span>
                    <span v-else @click="clickShowTree">
                        <i class="el-icon-s-unfold"></i> 展开 </span>
                </div>
                <el-tree
                    v-show="showTree"
                    ref="tree"
                    :data="treeData"
                    :props="defaultProps"
                    :expand-on-click-node="false"
                    :default-checked-keys="defaultCheckedKeys"
                    highlight-current
                    :current-node-key="currentNodeKey"
                    node-key="code"
                    accordion
                    @node-click="handleNodeClick" />
            </div>
        </div>
    </div>
</template>

<script>
import {
    Component,
    Inject,
    Prop,
    Vue,
    Watch,
} from 'vue-property-decorator';
import MonitorView from '@/components/MonitorView/Index';
import { Loading } from '@/infrastructures/decorators/Loading';
import Timer from '@/infrastructures/timer/timer';

export default
    @Component({
    components: {
        MonitorView,
    },
})
class MonitorViewExtendComponent extends Vue {
    @Inject() notifier;
    @Inject() repository;

    @Prop() width;
    @Prop() height;
    @Prop() TitleHandler;
    @Prop() PageHandler;
    @Prop() PageDataHandler;

    async mounted () {
        this.showLeftMenu = this.context.leftMenu ? true : false;
        this.autoNextGroup = this.showLeftMenu;
        if (this.showLeftMenu) {
            // 查询左侧菜单  // 设置 treeData 的值
            this.initData();
            // await this.GetOnlineEquipments();
            // watch 逻辑中有加载数据方法

        } else {
            await this.GetExaminationSiteData();
            await this.GetOnlineEquipmentCount();
            await this.GetOnlineEquipments();
            this.mutedAndPlay()
        }
        this.enableCarousel = true
    }

    mutedAndPlay(){
        setTimeout(() => {
            console.log('mutedAndPlay', this.autoMute)
             let el = document.querySelectorAll('.vcp-player video')
                el.forEach(v=> {
                    v.muted = this.autoMute
                    v.play()
               })
        }, 3000);
    }

    @Watch('page.cur', { immediate: true, deep: true })
    @Loading('数据加载中')
    async LoadPageData () {
        // alert('page')
        if (!this.context.leftMenu) {
            await this.GetOnlineEquipmentCount();
            await this.GetOnlineEquipments();
            this.mutedAndPlay()
        } else {
            this.initData();
        }
    }

    showLeftMenu = false;
    showTree = true;
    autoMute= true
    defaultExpandedKeys = [];
    defaultCheckedKeys = [];
    currentNodeKey = null;
    treeData = [];
    defaultProps = {
        label: 'fullName',
        children: 'children',
        // isLeaf: 'isLeaf',
    };
    currentKey = 0;
    taskTime = 60;
    canSwitch = true
    page ={
        cur: 0,
        size:0,
        count: 0
    }

    clickShowTree () {
        this.showTree = true;
    }

    clickHidenTree () {
        this.showTree = false;
    }

    async handleNodeClick (node) {
        console.log('点击数节点===>', node,);
        this.currentKey = node.code;
        let code = node.code;
        await this.GetOnlineEquipmentCount2(code);
        await this.GetOnlineEquipments2(code);
        // alert(node)
    }

    async initData () {
        await this.GetExaminationSites();
        const code = this.treeData[0].code;
        // alert(1)
        await this.GetOnlineEquipmentCount2(code);
        await this.GetOnlineEquipments2(code);
        // alert(3)
    }

    async GetOnlineEquipmentCount () {
        if (!this.PageHandler) return;
        let count = await this.PageHandler();
        this.page.count = count

        // this.page.count =
        //     Math.floor(count / this.page.size) +
        //     (count % this.page.size === 0 ? 0 : 1);
    }

    async GetOnlineEquipments2 (code) {
        let roomType = localStorage.getItem('roomType');
        let response =
            await this.repository.ExaminationRoom.GetOnlineMonitorEquipments({
                groupNo: code,
                pageSize: this.page.size,
                page: this.page.cur,
                roomType: roomType,
            });
        this.monitors = response.data;
    }

    async GetOnlineEquipmentCount2 (code) {
        // return new Promise(resolve => {})
        // eslint-disable-next-line
        let roomType = localStorage.getItem('roomType');
        let response =
            await this.repository.ExaminationRoom.GetOnlineEquipmentCount({
                groupNo: code,
                roomType: roomType,
            });
        // alert(2)
        // eslint-disable-next-line
        let count = response.data;
        this.page.count =
            Math.floor(count / this.page.size) +
            (count % this.page.size === 0 ? 0 : 1);
    }

    GetExaminationSites () {
        return new Promise(resolve => {
            let roomType = localStorage.getItem('roomType');
            const param = {};
            param.roomType = roomType;
            this.repository.ExaminationSite.GetExaminationSites(
                param
            ).then((res) => {
                // alert(1)
                resolve();
                // alert(res.data)
                this.treeData = res.data.map(v => {
                    v.fullName = v.code + '-' + v.name;
                    return v;
                });
            });
        });

    }

    // 返回当前当前考点的监控 url ，推流信息
    async GetOnlineEquipments () {
        // eslint-disable-next-line
        // debugger
        this.monitors = [];
        if (!this.PageDataHandler) return;
        this.monitors = await this.PageDataHandler(this.page);
    }

    async GetExaminationSiteData () {
        // eslint-disable-next-line
        // debugger
        if (!this.TitleHandler) return;
        this.examinationSites = await this.TitleHandler();
        if (this.examinationSites.length > 0) {
            this.examinationSite = this.examinationSites[0];
        }
    }

    prev () {
        // if (!this.showLeftMenu) {
            if (this.page.cur <= 1) {
                this.notifier.warn('已经是第一页!');
                return;
            }
            this.page.cur -= 1;
        // }

    }

    next () {
        if (this.page.cur * this.page.size >= this.page.count) {
            this.notifier.warn('已经是最后一页!');
            return;
        }
        this.page.cur += 1;
    }

    @Watch('treeData')
    setTreeKey () {
        this.$nextTick(() => {
            this.defaultCheckedKeys = [this.treeData[0].code];
            this.currentNodeKey = this.treeData[0].code;
            this.$refs.tree.setCurrentKey(this.treeData[0].code);
        });
    }

    @Watch('enableCarousel')
    async OnCarouselStatusChanged (val) {
        if (val) {
            this.carouselTimer = Timer.CreateFromSecond(this.VideoCarousel, this.taskTime)
            this.carouselTimer.Start();
        } else {
            if (this.carouselTimer) this.carouselTimer.Stop();
            this.carouselTimer = null
        }
    }

    @Watch('autoMute')
    async OnMuteChanged (val) {
        console.log('autoMute' , val)
        let el = document.querySelectorAll('.vcp-player video')
                el.forEach(v=> {
                    v.muted = val
        })
    }

    validateTime(event) {
       const value = event.target.value
       if (Number.isNaN(Number(value))) {
            this.carousel = false
            this.notifier.warn('请输入的数字!');
            this.canSwitch = false
            // return;
        } else if (Number(value) < 30) {
            this.taskRefresh = false;
            this.notifier.warn('请输入大于30的数字');
            this.canSwitch = false
        } else {
            this.canSwitch = true
        }
    }
    async VideoCarousel () {
        // let page = (this.page.cur + 1) % this.page.count;
        // if (page === 0) page = this.page.count;
        // this.page.cur = page;
        if (this.page.cur * this.page.size >= this.page.count) {
            // this.notifier.warn('已经是最后一页!');
            // return;
            this.page.cur = 1;
        } else {
            this.page.cur += 1;
        }
    }

    @Watch('selectedTypeIndex', {immediate: true,})
    OnViewTypeChanged () {
        let type = this.viewType[this.selectedTypeIndex];
        let isFirstPage = this.page.cur === 1;
        this.page.size = type.value;
        this.page.cur = 1;
        if (isFirstPage) {
            this.LoadPageData();
        }
    }

    examinationSite = {};
    monitors = [];
    page = {
        cur: 1,
        count: 1,
        size: 4,
    };

    btnProps = {
        color: 'white',
        outlined: true,
        class: 'mr-2',
    };
    selectedTypeIndex = 1;
    viewType = [
        { text: '单个', value: 1 },
        { text: '四宫格', value: 4 },
        { text: '六宫格', value: 6 },
        { text: '九宫格', value: 9 },
    ];
    enableCarousel = false;
    enableFil = true;
    // carouselTimer = Timer.CreateFromMinute(this.VideoCarousel, 1);
    carouselTimer = null;
}
</script>

<style lang="scss" scoped>
.full {
    width: 100%;
    height: 100%;
}
.task_time {
    position: relative;
    .time_input {
    width: 80px;
    border-radius: 5px;
    border: 1px solid #fff;
    font-size: 14px;
    color: #fff;
    padding-left: 10px;
    // margin-top: 20px;
    height: 36px;
    margin-right: 5px;
}
    .unit {
        position: absolute;
        right: 6px;
        top: 6px;
    }
}

.newMonitor {
    .selectTree {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 48px;
        max-height: calc(100vh - 48px);
        overflow-x: hidden;
        overflow-y: auto;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;

        // min-width: 160px;
        ::v-deep .el-tree {
            background: none;
            color: rgba(255, 255, 255, 0.85);
            font-size: 16px;
            line-height: 36px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            padding: 10px;

            .el-tree-node__label {
                font-size: 16px;
            }

            .el-menu-item.is-active {
                background-color: rgb(201, 188, 80) !important;
            }

            .el-tree-node__content {
                height: 36px;
                padding-right: 10px;

                &:hover {
                    // background: #89c2f7;
                    background: rgba(255, 255, 255, 0.1);
                }
            }

            .el-tree-node:focus>.el-tree-node__content {
                // background-color: #5daaf0;
                background: rgba(93, 170, 240, 0);
            }

            .el-tree-node.is-current>.el-tree-node__content {
                // background: #5daaf0;
                background: rgba(93, 170, 240, 0.2);
            }
        }

        .cont {
            // padding: 10px;
            background: rgba(255, 255, 255, 0.08);

            .menuBar {
                position: sticky;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10;
                background: rgba(0, 0, 0, 0.9);
                padding: 8px 15px;

                span {
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
