<template>
    <div>
        <div class="header d-flex primary">
            <div>
                <h3 class="title pt-2 pl-2">{{ title }}</h3>
            </div>
            <div class="ml-auto">
                <v-tabs
                    background-color="primary"
                    class="subtitle-1"
                    dark
                    right
                >
                    <v-tab :to="$to($views.ExamCsp.ExaminationDistrict.List)">
                        考区管理
                    </v-tab>
                    <v-tab :to="$to($views.ExamCsp.ExaminationReport.Index)">
                        监控统计
                    </v-tab>
                    <v-tab :to="$to($views.ExamCsp.Playback.Index)">
                        视频回放
                    </v-tab>
                </v-tabs>
            </div>
            <div class="my-auto yellow--text caption d-flex">
                <p class="mr-n2 mb-0 pt-3">管理员，欢迎您</p>
                <div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                text
                                fab
                                small
                                @click="changePasswordDialog = true"
                            >
                                <v-icon color="white">
                                    mdi-square-edit-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>修改密码</span>
                    </v-tooltip>
                </div>
            </div>
            <div class="login-out ml-n3">
                <v-menu offset-y bottom>
                    <template v-slot:activator="{ on }">
                        <v-avatar class="login-out white--text" v-on="on">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" text fab small>
                                        <v-icon color="white">
                                            mdi-power-standby
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>退出登录</span>
                            </v-tooltip>
                        </v-avatar>
                    </template>
                    <v-list>
                        <v-list-item @click="loginOut()">
                            <v-list-item-title>退出登录</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <div class="main scroll examCsp-main">
            <router-view />
        </div>

        <v-footer fixed color="primary">
            <v-col cols="12" class="text-center pa-0 white--text">
                技术支持：深圳市卓帆技术有限公司
            </v-col>
        </v-footer>

        <v-dialog max-width="500" persistent v-model="changePasswordDialog">
            <password @passwordBack="passwordBack"></password>
        </v-dialog>
    </div>
</template>

<script>
import password from '@/components/changePassword/password';
import { Vue, Component, Inject } from 'vue-property-decorator';

export default
@Component({ components: { password } })
class ExamCspLayout extends Vue {
    @Inject()
    repository;
    @Inject()
    navigation;
    @Inject()
    user;
    title = '卓帆视频监控云服务平台('+localStorage.getItem('orgName')+')';

    changePasswordDialog = false;

    mounted() {
        var roomType = localStorage.getItem('roomType');
        if (roomType == 0) this.title = this.title + '(场内)';
        if (roomType == 1) this.title = this.title + '(场外)';
    }
    loginOut() {
        //清楚token
        const roomType = localStorage.getItem('roomType');
        this.user.Logout();
        roomType == -1
            ? this.navigation.redirect(window.$views.Login)
            : this.navigation.redirect(window.$views.Login, {
                  roomType: roomType,
              });
    }

    passwordBack() {
        this.changePasswordDialog = false;
    }
}
</script>
